import * as types from '@/store/types'
import request from '@/modules/request'
import rest from '@/modules/rest'

import SetCampaignsHighlighted from '@/store/campaign/SetCampaignsHighlighted'
import CreateCampaign from '@/store/campaign/CreateCampaign'
import UpdateCampaign from '@/store/campaign/UpdateCampaign'
import GetCampaignsPagination from '@/store/campaign/GetCampaignsPagination'
import ToggleCampaignStatus from '@/store/campaign/ToggleCampaignStatus'
import GetCampaign from '@/store/campaign/GetCampaign'
import DeleteCampaign from '@/store/campaign/DeleteCampaign'

const state = {
  list: [],
  campaign: { },
  highlighted: [],
  pagination: { list: [] },
  highlightedPreview: []
}

const getters = {
  [types.CAMPAIGN]: ({ campaign }) => ({ ...campaign }),
  [types.CAMPAIGN_LIST_PAGINATION]: ({ pagination }) => ({ ...pagination }),
  [types.CAMPAIGN_LIST_HIGHLIGHTED]: ({ highlighted }) => [ ...highlighted ],
  [types.CAMPAIGN_LIST_HIGHLIGHTED_PREVIEW]: ({ highlightedPreview }) => [ ...highlightedPreview ]
}

const mutations = {
  [types.CAMPAIGN]: (state, payload) => {
    state.campaign = payload
  },

  [types.CAMPAIGN_CREATE]: (state, payload) => {
    state.campaign = payload
  },

  [types.CAMPAIGN_CLEAR]: (state) => {
    state.campaign = {}
  },

  [types.CAMPAIGN_LIST_PAGINATION]: (state, payload) => {
    const list = [ ...payload.list, ...state.pagination.list ]
    state.pagination = { ...payload, list }
  },

  [types.CAMPAIGN_LIST_FILTER]: (state, payload) => {
    state.pagination = { ...payload }
  },

  [types.CAMPAIGN_LIST_HIGHLIGHTED]: (state, payload) => {
    state.highlighted = [ ...payload.list ]
  },

  [types.CAMPAIGN_TOGGLE_STATUS]: (state, { slug, campaign: { status } }) => {
    if (state.campaign.slug === slug) {
      state.campaign.status = status
    }

    state.list
      .concat(state.campaign, state.highlighted, state.pagination.list)
      .forEach(c => { if (c.slug === slug) c.status = status })
  },

  [types.CAMPAIGN_LIST_HIGHLIGHTED_PREVIEW]: (state, payload) => {
    state.highlightedPreview = [ ...payload ]
  }
}

const actions = {
  [types.CAMPAIGN]: async ({ commit }, filters) => {
    const { campaign } = await request(GetCampaign, filters)
    commit(types.CAMPAIGN, campaign)
  },

  [types.CAMPAIGN_CLEAR]: async ({ commit }) => {
    commit(types.CAMPAIGN_CLEAR)
  },

  [types.CAMPAIGN_DELETE]: async ({ commit, dispatch }, variables) => {
    const { status } = await request(DeleteCampaign, variables)

    if (status) {
      const message = { type: 'success', text: 'Oferta deletada com sucesso!' }
      dispatch(types.FEEDBACK, message)
    } else {
      const message = { type: 'error', text: 'Houve um erro ao deletar a oferta!' }
      dispatch(types.FEEDBACK, message)
    }
  },

  [types.CAMPAIGN_CREATE]: async ({ getters, dispatch }, payload) => {
    const { campaign, perk, file } = payload

    try {
      const { createCampaign: { id, slug } } = await request(CreateCampaign, { campaign, perk })

      const message = { type: 'success', text: 'Oferta criada com sucesso!' }
      dispatch(types.FEEDBACK, message)

      const company = getters[types.CONFIG_COMPANY_ID]
      const body = new FormData()
      body.append('file', file)

      if (file) {
        try {
          await rest().post(`company/${company}/perk/${perk}/campaign/${id}/voucher`, body)

          const message = { type: 'success', text: 'Cupom importado com sucesso!' }
          setTimeout(() => dispatch(types.FEEDBACK, message), 2750)
        } catch (error) {
          const message = { type: 'error', text: 'Houve um erro ao ler o cupom importado!' }
          setTimeout(() => dispatch(types.FEEDBACK, message), 2750)
        }
      }

      return slug
    } catch (error) {
      const message = { type: 'error', text: 'Houve um erro ao criar a oferta!' }
      dispatch(types.FEEDBACK, message)

      return false
    }
  },

  [types.CAMPAIGN_UPDATE]: async ({ getters, dispatch }, payload) => {
    const { campaign, perk, file, id } = payload

    const company = getters[types.CONFIG_COMPANY_ID]
    const body = new FormData()
    body.append('file', file)

    if (file) {
      try {
        await rest().post(`company/${company}/perk/${perk}/campaign/${id}/voucher`, body)

        const message = { type: 'success', text: 'Cupom importado com sucesso!' }
        dispatch(types.FEEDBACK, message)
      } catch (error) {
        const message = { type: 'error', text: 'Houve um erro ao ler o cupom importado!' }
        dispatch(types.FEEDBACK, message)
      }
    }

    try {
      await request(UpdateCampaign, { campaign, id })

      const message = { type: 'success', text: 'Oferta alterada com sucesso!' }
      file
        ? setTimeout(() => dispatch(types.FEEDBACK, message), 2750)
        : dispatch(types.FEEDBACK, message)

      return true
    } catch (error) {
      const message = { type: 'error', text: 'Houve um erro ao alterar a oferta!' }
      file
        ? setTimeout(() => dispatch(types.FEEDBACK, message), 2750)
        : dispatch(types.FEEDBACK, message)

      return false
    }
  },

  [types.CAMPAIGN_LIST_PAGINATION]: async ({ commit }, variables) => {
    const { pagination } = await request(GetCampaignsPagination, variables)

    if (variables['highlighted'] === '1') {
      commit(types.CAMPAIGN_LIST_HIGHLIGHTED, pagination)
    } else {
      commit(types.CAMPAIGN_LIST_PAGINATION, pagination)
    }
  },

  [types.CAMPAIGN_LIST_FILTER]: async ({ commit }, variables) => {
    const { pagination } = await request(GetCampaignsPagination, variables)

    if (variables['highlighted'] === '1') {
      commit(types.CAMPAIGN_LIST_HIGHLIGHTED, pagination)
    } else {
      commit(types.CAMPAIGN_LIST_FILTER, pagination)
    }
  },

  [types.CAMPAIGN_LIST_CLEAR]: ({ commit }) => {
    commit(types.CAMPAIGN_LIST_HIGHLIGHTED, { list: [] })
    commit(types.CAMPAIGN_LIST_FILTER, { list: [] })
  },

  [types.CAMPAIGN_TOGGLE_STATUS]: async ({ commit, dispatch }, variables) => {
    try {
      const response = await request(ToggleCampaignStatus, variables)
      const campaign = response[Object.keys(response)[0]]
      const statuses = { 0: 'inativo', 1: 'ativo', 2: 'agendado' }

      commit(types.CAMPAIGN_TOGGLE_STATUS, { ...variables, campaign })
      dispatch(types.FEEDBACK, {
        type: 'success',
        text: 'Status alterado para ' + statuses[campaign.status]
      })
    } catch (_) {
      const message = { type: 'error', text: 'Ocorreu um problema ao alterar o status' }
      dispatch(types.FEEDBACK, message)
    }
  },

  [types.CAMPAIGN_SET_HIGHLIGHTED]: async ({ dispatch }, payload) => {
    try {
      let dontShow = false

      if (payload.dontShowFeedback) {
        dontShow = true
        delete payload.dontShowFeedback
      }

      await request(SetCampaignsHighlighted, payload)

      if (dontShow) return

      const message = {
        type: 'success',
        text: 'As ofertas em destaque foram salvas com sucesso '
      }

      dispatch(types.FEEDBACK, message)
    } catch (_) {
      const message = {
        type: 'error',
        text: 'Ocorreu um problema ao salvar ofertas em destaques'
      }
      dispatch(types.FEEDBACK, message)
    }
  },

  [types.CAMPAIGN_LIST_HIGHLIGHTED_PREVIEW]: async ({ commit }, payload) => {
    commit(types.CAMPAIGN_LIST_HIGHLIGHTED_PREVIEW, payload)
  }
}

export default { state, getters, mutations, actions }
