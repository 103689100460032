
export const primaryMenu = permissions => ([
  {
    name: 'Clube',
    icon: 'arrow-down',
    path: { name: 'parceiros' },
    dropdown: true
  },
  ...(
    permissions.some(permission => permission === 'awards')
      ? [{
        name: 'Premiações',
        path: { name: 'premiacoes' },
        dropdown: false
      }]
      : []
  ),
  ...(
    permissions.some(permission => permission === 'engagement')
      ? [{
        name: 'Engajamento',
        path: { name: 'engajamento' },
        dropdown: false
      }]
      : []
  ),
  ...(
    permissions.some(permission => permission === 'dashboard')
      ? [{
        name: 'Relatórios',
        path: { name: 'relatorios' },
        dropdown: false
      }]
      : [{
        name: 'Relatórios',
        path: { name: 'relatorios-relatorios' },
        dropdown: false
      }]
  ),
  ...(
    [{
      name: 'Carteira',
      path: { name: 'carteira' },
      dropdown: false,
      analyticsEvent: 'carteira-acessar'
    }]
  )
])

export const primaryMenuMobile = permissions => ([
  {
    name: 'Parceiros',
    icon: 'user',
    path: { name: 'parceiros' }
  },
  ...(
    permissions.some(permission => permission === 'highlight')
      ? [{
        name: 'Comunicação',
        icon: 'megaphone',
        path: { name: 'comunicacao' }
      }]
      : []
  ),
  {
    name: 'Usuários',
    icon: 'user',
    path: { name: 'usuarios' }
  },
  ...(
    permissions.some(permission => permission === 'awards')
      ? [{
        name: 'Premiações',
        icon: 'notebook',
        path: { name: 'premiacoes' }
      }]
      : []
  ),
  ...(
    permissions.some(permission => permission === 'dashboard')
      ? [{
        name: 'Relatórios',
        icon: 'notebook',
        path: { name: 'relatorios' }
      }]
      : [{
        name: 'Relatórios',
        icon: 'notebook',
        path: { name: 'relatorios-relatorios' }
      }]
  ),
  ...(
    permissions.some(permission => permission === 'engagement')
      ? [{
        name: 'Engajamento',
        icon: 'notebook',
        path: { name: 'engajamento' }
      }]
      : []
  )
])
export const secondaryMenu = [
  {
    name: 'Suporte',
    icon: 'question-circle',
    path: 'https://clubealelo.kayako.com/',
    external: true,
    arrow: true
  },
  {
    name: 'Sair',
    icon: 'exit',
    path: { name: 'logout' },
    arrow: false
  }
]

export const dropdownMenu = permissions => ([
  {
    name: 'Usuários',
    icon: 'users',
    path: { name: 'usuarios' },
    description: 'Adicione, exclua e gerencie os usuários do seu Clube'
  },
  ...(
    permissions.some(permission => permission === 'highlight')
      ? [{
        name: 'Comunicação',
        icon: 'emails-letter-mail',
        path: { name: 'comunicacao' },
        description: 'Crie ofertas em destaque e cadastre seus banners.'
      }]
      : []
  ),
  {
    name: 'Parceiros',
    icon: 'crown-circle',
    path: { name: 'parceiros' },
    description: 'Gerencie e cadastre suas marcas parceiras.'
  }
])

export default { primaryMenu, primaryMenuMobile, secondaryMenu, dropdownMenu }
