import * as types from '@/store/types'

const state = {
  names: {
    '/': 'Início'
  },
}

const getters = {
  [types.BREADCRUMB]: ({ names }) => Object.keys(names)
    .map(path => ({
      name: names[path],
      link: { path }
    }))
}

const mutations = {
  [types.BREADCRUMB_NAMES]: (state, names) => {
    state.names = { ...state.names,
      ...names
    }
  },

  [types.BREADCRUMB_ROUTES]: (state, routes) => {
    state.routes = routes
  },
}

const actions = {
  [types.BREADCRUMB_NAMES]: ({
    commit
  }, names) => commit(types.BREADCRUMB_NAMES, names),

  [types.BREADCRUMB_ROUTES]: ({
    commit
  }, to) => {
    const query = to.query

    const routes = to.matched.map(
      ({
        params,
        path
      }) => ({
        path,
        link: {
          params,
          path,
          query
        },
      })
    )

    commit(types.BREADCRUMB_ROUTES, routes)
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
