import moment from 'moment'
import { getDateFormat, getConstructor } from './helpers'
const EmailValidation = require('emailvalid')
const ev = new EmailValidation()

export const is = (value, constructor) => {
  const isEquals = constructor === getConstructor(value)
  return isEquals
}

export const isCPF = (cpf) => {
  const isInvalid = (cpf, rest, pos) => rest !== parseInt(cpf.substring(pos, pos + 1))

  const sumDigit = (cpf, digit) => 11 - (cpf.substring(0, digit).split('').reduce((acc, curr, index) => {
    acc += parseInt(curr) * ((digit + 1) - index)
    return acc
  }, 0) % 11)

  const getRest = sum => sum > 9 ? 0 : sum

  if (!is(cpf, 'String')) return false

  cpf = cpf.replace(/[\D]/gi, '')

  if (!cpf.match(/^\d+$/)) return false
  const invalids = ['00000000000', '11111111111', '22222222222', '33333333333', '44444444444', '55555555555', '66666666666', '77777777777', '88888888888', '99999999999']

  if (invalids.includes(cpf) || cpf.length !== 11) return false

  if (isInvalid(cpf, getRest(sumDigit(cpf, 9)), 9)) return false

  if (isInvalid(cpf, getRest(sumDigit(cpf, 10)), 10)) return false

  return true
}

export const isDate = (date, format = null) => {
  const from = format || getDateFormat(date)
  const isValid = from ? moment(date, from).isValid() : false
  return isValid
}

export const isValidDate = (date) => {
  return !date || moment(date, 'DD/MM/YYYY', true).isValid() || moment(date, 'YYYY-MM-DD', true).isValid()
}

export const isCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/\D/g, '')

  if (cnpj.length !== 14 || /^(.)\1*$/.test(cnpj)) {
    return false
  }

  const weights1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  const weights2 = [6, ...weights1] // Update the weights for the second digit

  function calculateDigit (digits, weights) {
    const sum = digits.reduce((acc, digit, index) => acc + digit * weights[index], 0)
    const remainder = sum % 11
    return remainder < 2 ? 0 : 11 - remainder
  }

  const digit1 = calculateDigit(cnpj.slice(0, 12).split('').map(Number), weights1)
  const digit2 = calculateDigit(cnpj.slice(0, 13).split('').map(Number), weights2)

  return cnpj.slice(-2) === `${digit1}${digit2}`
}

export const isProfessionalEmail = (email) => {
  email = email.toLowerCase()
  const result = ev.check(email)

  return result.valid && isEmail(email)
}

export const isEmail = (value) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  const isValid = is(value, 'String') && emailRegex.test(value)
  return isValid
}

export const isHexColor = (value) => {
  const isValid = is(value, 'String') && /^#[0-9A-F]{6}$/i.test(value)
  return isValid
}

export const isPasswordValid = (password) => {
  const allRules = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\d\s])/
  if (!password) return false
  return !!(password.length >= 8 && password.match(allRules))
}

export const isPhone = (value) => {
  const phoneNumber = value.replace(/\D/g, '')
  const onlyNumbersRegex = /^\d+$/
  const validLength = phoneNumber.length === 10 || phoneNumber.length === 11

  return onlyNumbersRegex.test(phoneNumber) && validLength
}
export const minLengthValidation = (value, minLength) => {
  return value.length >= minLength
}

export const maxLengthValidation = (value, maxLength) => {
  return value.length <= maxLength
}

export const isValueValid = (value) => {
  return !(value === 0 || value === '' || value === null || (Array.isArray(value) && value.length === 0))
}

export const isUrl = (url) => {
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/

  return urlPattern.test(url)
}
