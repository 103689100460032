import Vue from 'vue'
import Vuex from 'vuex'
import cache from 'vuex-cache'
import handler from 'vuex-handler'
import modules from '@/store/modules'
import persistence from 'vuex-localstorage'
import * as Sentry from '@sentry/vue'
import { Replay } from '@sentry/browser'
import Can from '@/helpers/can'
import { release } from '../release'
import { name, version } from '@/package'
import { makeServer } from '@/mocks/server.js'

// Global components
import CButton from '@/components/CComponents/CButton'
import CSpinner from '@/components/CComponents/CSpinner'
import CEmpty from '@/components/CComponents/CEmpty'
import CIcon from '@/components/CComponents/CIcon'
import CToggle from '@/components/CComponents/CToggle'
import CTitle from '@/components/CComponents/CTitle'
import CInput from '@/components/CComponents/CInput'
import CSelect from '@/components/CComponents/CSelect'

// Registration of global components
Vue.component('CButton', CButton)
Vue.component('CSpinner', CSpinner)
Vue.component('CEmpty', CEmpty)
Vue.component('CIcon', CIcon)
Vue.component('CToggle', CToggle)
Vue.component('CTitle', CTitle)
Vue.component('CInput', CInput)
Vue.component('CSelect', CSelect)
Vue.mixin(Can)

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  Sentry.init({
    Vue,
    dsn: process.env.sentryDnsKey,
    release: 'clube-gestor-front@' + release,
    include: './dist',
    ignore: ['node_modules'],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Replay({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    logErrors: true,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'Failed to fetch',
      'Network request failed',
      'Failed to fetch',
      'Request aborted',
      'Network Error',
      'Non-Error promise rejection captured',
      'Navigation cancelled from',
      'Navigation aborted from',
      'Avoided redundant navigation to current location',
      'Blocked a frame with origin',
      "'state' should be a method that returns an object in store",
      'chrome_debugger_trace',
      /Loading chunk [\d]+ failed/
    ]
  })
}
if (process.env.USE_MOCK === 'true') {
  makeServer()
}

const cleanModules = JSON.parse(JSON.stringify(modules))

let store

const settings = {
  namespace: `${name}@${version}`,
  timeout: 2 * 24 * 60 * 60 * 1000
}

const createInitialState = () => {
  return Object.keys(cleanModules)
    .filter(module => !!cleanModules[module].state)
    .reduce((state, module) => ({
      ...state,
      [module]: JSON.parse(JSON.stringify(cleanModules[module].state))
    }), {})
}

const createStore = () => {
  return store || (store = new Vuex.Store({
    plugins: [cache, handler, persistence(settings)],
    strict: true,
    modules
  }))
}

export { createInitialState }
export default createStore
