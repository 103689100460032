import * as types from '@/store/types'
import rest from '@/modules/rest'
import moment from 'moment'

const state = {
  medias: [],
  canCreateBanners: null,
  success: null
}

const getters = {
  [types.MEDIAS]: (state) => state.medias,
  [types.CAN_CREATE_BANNERS]: (state) => state.canCreateBanners,
  [types.SUCCESS]: (state) => state.success
}

const mutations = {
  [types.MEDIAS]: (state, payload) => {
    state.medias = payload
  },

  [types.MEDIA]: (state, payload) => {
    state.medias[payload.index] = payload.data
  },

  [types.CAN_CREATE_BANNERS]: (state, payload) => {
    state.canCreateBanners = payload.value
  },

  [types.SUCCESS]: (state, payload) => {
    state.success = payload === 200
  }
}

const actions = {
  [types.MEDIAS]: ({ commit }, payload) => {
    return rest().get('/banner').then(res => {
      commit(types.MEDIAS, res.data.data)
    })
  },

  [types.MEDIA]: ({}, payload) => {
    return rest().get(`/banner/${payload.banner_id}`)
  },

  [types.CAN_CREATE_BANNERS]: ({ state, commit }, payload) => {
    const activeBanners = state.medias.filter(object => {
      return object.status === 1
    })
    const activeBannersNumber = activeBanners.length
    if (activeBannersNumber >= 3) {
      commit(types.CAN_CREATE_BANNERS, {
        value: false
      })
    } else {
      let futureBanners = 0
      state.medias.forEach(media => {
        const startDate = moment.unix(media.start_date, 'DD/MM/YYYY')
        if (startDate.isAfter(Date.now())) {
          futureBanners += 1
        }
      })
      if (activeBannersNumber + futureBanners >= 3) {
        commit(types.CAN_CREATE_BANNERS, {
          value: false
        })
      } else {
        commit(types.CAN_CREATE_BANNERS, {
          value: true
        })
      }
    }
  },

  [types.CREATE_MEDIA]: ({ state, commit }, payload) => {
    const formData = new FormData()

    Object.keys(payload)
      .filter(key => (typeof payload[key] !== 'undefined') && (typeof payload[key] !== 'object' || !!payload[key]))
      .forEach(key => formData.append(key, payload[key]))

    return rest().post('/banner', formData).then(res => {
      const auxMedias = [ ...state.medias ]
      auxMedias.push(res.data.data)
      commit(types.MEDIAS, auxMedias)
      commit(types.SUCCESS, res.status)
    })
  },

  [types.UPDATE_MEDIA]: ({ state, commit }, payload) => {
    let id = ''
    let sendData = ''

    if (!payload.media) {
      id = payload.id
      const formData = new FormData()

      Object.keys(payload)
        .filter(key => (typeof payload[key] !== 'undefined') && (typeof payload[key] !== 'object' || !!payload[key]))
        .forEach(key => formData.append(key, payload[key]))

      sendData = formData
    } else {
      id = payload.media.id
      sendData = payload
    }

    return rest().patch(`/banner/${id}`, sendData).then(res => {
      const indexMedia = state.medias.findIndex(media => {
        return media.id === res.data.data.id
      })

      commit(types.MEDIA, {
        index: indexMedia,
        data: res.data.data
      })

      commit(types.SUCCESS, res.status)
    })
  },

  [types.DELETE_MEDIA]: ({ state, commit }, payload) => {
    return rest().delete(`/banner/${payload.banner_id}`).then(() => {
      let auxMedias = [ ...state.medias ]
      auxMedias = auxMedias.filter(media => media.id !== payload.banner_id)
      commit(types.MEDIAS, auxMedias)
    })
  }
}

export default { state, getters, mutations, actions }
