import { COMPANY_PERMISSIONS } from '@/store/types'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      CanCompanyPermissions: COMPANY_PERMISSIONS
    })
  },
  methods: {
    $can (permission) {
      return this.CanCompanyPermissions.some((v) => v === permission)
    }
  }

}
