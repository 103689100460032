/**
 * @name fixture-global-environment
 * @desc Resolve o erro causado pelo uso do "process.env" (DedupePlugin) usado
 * numa expressão passada como argumento para o "import" dinâmico que não
 * é resolvida durante a eliminação de código morto.
 */
global.process = {
  ...process,
  env: {
    ...process.env,
    NODE_ENV: process.env.NODE_ENV
  }
}
