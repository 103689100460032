import * as types from '@/store/types'
import rest from '@/modules/rest'
import { FEEDBACK } from '@/store/types'

const state = {
  engagement_campaigns_step: 1,
  engagement_campaigns: [],
  engagement_campaigns_images: []
}

const getters = {
  [types.ENGAGEMENT_CAMPAIGNS_STEP]: ({ engagement_campaigns_step }) => engagement_campaigns_step,
  [types.ENGAGEMENT_CAMPAIGNS]: ({ engagement_campaigns }) => engagement_campaigns,
  [types.ENGAGEMENT_CAMPAIGNS_IMAGES]: ({ engagement_campaigns_images }) => engagement_campaigns_images
}

const mutations = {
  [types.ENGAGEMENT_CAMPAIGNS_STEP]: (state, payload) => {
    state.engagement_campaigns_step = payload
  },
  [types.ENGAGEMENT_CAMPAIGNS]: (state, payload) => {
    state.engagement_campaigns = payload
  },
  [types.ENGAGEMENT_CAMPAIGNS_IMAGES]: (state, payload) => {
    state.engagement_campaigns_images = payload
  }
}

const actions = {
  [types.GET_ENGAGEMENT_CAMPAIGN_DETAILS]: async ({ dispatch }, payload) => {
    const { id } = payload
    try {
      const engagementDetails = await rest().get(`/engagement/campaign/${id}`)
      return engagementDetails.data.data
    } catch (error) {
      const feedback = {
        type: 'error',
        title: 'Houve um erro ao acessar a campanha',
        text: 'Tente novamente mais tarde'
      }
      dispatch(types.FEEDBACK, feedback)
      return {}
    }
  },
  [types.CREATE_ENGAGEMENT_CAMPAIGN]: async ({ dispatch }, payload) => {
    const formData = new FormData()
    Object.keys(payload)
      .filter(key => (typeof payload[key] !== 'undefined') && (typeof payload[key] !== 'object' || !!payload[key]))
      .forEach(key => formData.append(key, payload[key]))
    try {
      const res = await rest().post('/engagement/campaign', formData)
      const success = res && res.data && res.data.success
      if (success) {
        const feedback = {
          type: 'success',
          title: 'Campanha criada com sucesso',
          text: ''
        }
        dispatch(types.FEEDBACK, feedback)
      } else {
        const feedback = {
          type: 'error',
          title: 'Houve um erro ao criar a sua campanha',
          text: 'Tente novamente mais tarde'
        }
        dispatch(types.FEEDBACK, feedback)
      }
      return success
    } catch (e) {
      const feedback = {
        type: 'error',
        title: 'Houve um erro ao criar a sua campanha',
        text: 'Tente novamente mais tarde'
      }
      dispatch(types.FEEDBACK, feedback)
      return false
    }
  },
  [types.GET_ENGAGEMENT_CAMPAIGNS]: async ({ commit, dispatch }, variables) => {
    try {
      const engagementCampaigns = await rest().get('/engagement/campaign', {
        params: {
          ...variables
        }
      })
      commit(types.ENGAGEMENT_CAMPAIGNS, engagementCampaigns.data)
    } catch (error) {
      dispatch(FEEDBACK, {
        type: 'error',
        title: 'Ocorreu um erro. Tente novamente.',
        text: ''
      })
    }
  },
  [types.GET_ENGAGEMENT_CAMPAIGNS_IMAGES]: async ({ commit }) => {
    const images = await rest().get('/engagement/campaign/images')
    commit(types.ENGAGEMENT_CAMPAIGNS_IMAGES, images.data)
  },
  [types.UPDATE_ENGAGEMENT_CAMPAIGN]: async ({ dispatch }, payload) => {
    const { id } = payload
    const isActive = payload.status
    let status = isActive ? 0 : 1
    if (isActive === 2) status = 0
    const res = await rest().put(`/engagement/campaign/${id}/status`, {
      params: {
        status
      }
    })

    if (res && res.data && res.data.success) {
      dispatch(FEEDBACK, {
        type: 'success',
        title: 'Status alterado com sucesso!',
        text: ''
      })
    } else {
      dispatch(FEEDBACK, {
        type: 'error',
        title: 'Ocorreu um erro. Tente novamente.',
        text: ''
      })
    }
    return res
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
