export const hasEmptyAttribute = (data) => {
  return Object.values(data).every(x => x === null || x === '')
}

export function formatResponse (response, matchModel) {
  if (Array.isArray(response.data[matchModel])) {
    return response.data[matchModel][0]
  } else {
    return response.data[matchModel]
  }
}

export function datetimeToUnixEpoch(datetimeStr) {
  const date = new Date(datetimeStr);
  date.setHours(0, 0, 0, 0);
  const unixEpochTime = parseInt((date.getTime() / 1000).toFixed(0))
  return unixEpochTime;
}

export function searchObjectsByAttribute(arr, attribute, searchTerm) {
  return arr.filter(obj => obj[attribute].toLowerCase().includes(searchTerm.toLowerCase()));
}

export function searchObjectsByStatus(arr, searchTerm) {
  const statuses = {
    'canceled': {
      value: 0
    },
    'confirmed': {
      value: 1
    },
    'waiting_payment': {
      value: 3
    },
    'schedulled': {
      value: 4
    }
  }

  return arr.filter(obj => obj.status === statuses[searchTerm].value);
}


export function generateRandomCPF() {
  var cpf = "";

  for (var i = 0; i < 9; i++) {
    cpf += Math.floor(Math.random() * 10);
  }

  var sum = 0;
  for (var i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }

  var digit1 = 11 - (sum % 11);
  if (digit1 > 9) {
    digit1 = 0;
  }

  cpf += digit1;

  sum = 0;
  for (var i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }

  var digit2 = 11 - (sum % 11);
  if (digit2 > 9) {
    digit2 = 0;
  }

  cpf += digit2;

  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function getRandomBoolean() {
  return Math.random() < 0.5;
}
