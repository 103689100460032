import * as types from '@/store/types'
import rest from '@/modules/rest'
import { FEEDBACK } from '@/store/types'

const state = {
  coin: {},
  catalog: [],
  catalog_pagination: {}
}

const getters = {
  [types.COIN]: ({ coin }) => {
    return coin
  },
  [types.CATALOG]: ({ catalog }) => {
    return catalog
  },
  [types.CATALOG_PAGINATION]: ({ catalog_pagination }) => {
    return catalog_pagination
  }
}

const mutations = {
  [types.COIN]: (state, payload) => {
    state.coin = payload
  },
  [types.CATALOG]: (state, payload) => {
    state.catalog = payload
  },
  [types.CATALOG_PAGINATION]: (state, payload) => {
    state.catalog_pagination = payload
  }
}

const actions = {
  [types.GET_COIN]: async ({ commit, dispatch }) => {
    try {
      const res = await rest().get('/engagement/coin')
      if (res && res.data && res.status === 200) {
        commit(types.COIN, res.data.data)
        return res
      } else {
        dispatch(FEEDBACK, {
          type: 'error',
          title: 'Ocorreu um erro ao obter os dados de personalização',
          text: ''
        })
      }
      return {}
    } catch (error) {
      dispatch(FEEDBACK, {
        type: 'error',
        title: 'Ocorreu um erro ao obter os dados de personalização',
        text: ''
      })
      return {}
    }
  },
  [types.CREATE_COIN]: async ({ dispatch }, payload) => {
    const formData = new FormData()

    Object.keys(payload)
      .filter(key => (typeof payload[key] !== 'undefined') && (typeof payload[key] !== 'object' || !!payload[key]))
      .forEach(key => formData.append(key, payload[key]))
    try {
      const res = await rest().post('/engagement/coin', formData)
      if (res && res.status === 201) {
        dispatch(FEEDBACK, {
          type: 'success',
          title: 'Moeda criada com sucesso!'
        })
        return res
      } else {
        dispatch(FEEDBACK, {
          type: 'error',
          title: 'Ocorreu um erro ao criar sua moeda!',
          text: 'Por favor, tente novamente mais tarde'
        })
      }
    } catch (error) {
      dispatch(FEEDBACK, {
        type: 'error',
        title: 'Ocorreu um erro ao criar sua moeda!',
        text: 'Por favor, tente novamente mais tarde'
      })
    }
    return {}
  },
  [types.UPDATE_COIN]: async ({ commit, dispatch }, payload) => {
    const formData = new FormData()
    // delete payload.logo
    if (payload.program_image.length === 0) {
      delete payload.program_image
    }

    Object.keys(payload)
      .filter(key => (typeof payload[key] !== 'undefined') && (typeof payload[key] !== 'object' || !!payload[key]))
      .forEach(key => formData.append(key, payload[key]))

    try {
      const res = await rest().put(`/engagement/coin/${payload.id}`, formData)
      if (res && res.data && res.status === 200) {
        commit(types.COIN, res.data)
        dispatch(FEEDBACK, {
          type: 'success',
          title: 'Moeda atualizada com sucesso!',
          text: ''
        })
        return res
      } else {
        dispatch(FEEDBACK, {
          type: 'error',
          title: 'Ocorreu um erro ao criar sua moeda!',
          text: 'Por favor, tente novamente mais tarde'
        })
      }
    } catch (error) {
      dispatch(FEEDBACK, {
        type: 'error',
        title: 'Ocorreu um erro ao criar sua moeda!',
        text: 'Por favor, tente novamente mais tarde'
      })
    }
    return {}
  },
  [types.GET_CATALOG]: async ({ commit, dispatch }, payload) => {
    try {
      const { page } = payload
      const res = await rest().get(`/engagement/partner?page=${page}&limit=12`)
      if (res && res.data && res.status === 200) {
        commit(types.CATALOG, res.data.data)
        commit(types.CATALOG_PAGINATION, res.data)
        return res
      } else {
        dispatch(FEEDBACK, {
          type: 'error',
          title: 'Ocorreu um erro ao obter o catalogo',
          text: 'Por favor, tente novamente mais tarde'
        })
      }
    } catch (error) {
      dispatch(FEEDBACK, {
        type: 'error',
        title: 'Ocorreu um erro ao obter o catalogo',
        text: 'Por favor, tente novamente mais tarde'
      })
    }
    return []
  },
  [types.TOGGLE_CATALOG_STATUS]: async ({ dispatch }, payload) => {
    try {
      const { id, status } = payload
      const realStatus = status ? 1 : 0
      const res = await rest().put(`/engagement/partner/${id}/status`, {
        status: realStatus
      })
      if (res && res.status === 200) {
        dispatch(FEEDBACK, {
          type: 'success',
          title: 'Status alterado com sucesso!',
          text: ''
        })
      } else {
        dispatch(FEEDBACK, {
          type: 'error',
          title: 'Ocorreu um erro ao atualizar o status',
          text: 'Por favor, tente novamente mais tarde'
        })
      }
    } catch (error) {
      dispatch(FEEDBACK, {
        type: 'error',
        title: 'Ocorreu um erro ao atualizar o status',
        text: 'Por favor, tente novamente mais tarde'
      })
    }
    return {}
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
