import { ACCOUNT_CREATE, FEEDBACK } from '@/store/types'
import rest from '@/modules/rest'

const actions = {
  [ACCOUNT_CREATE]: async ({ dispatch }, payload) => {
    try {
      const res = await rest().post('/create/account', payload)
      const feedback = {
        type: 'success',
        title: 'Conta criada com sucesso',
        text: ''
      }
      dispatch(FEEDBACK, feedback)
      return res.data
    } catch (error) {
      const hasErrorMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.data &&
        (error.response.data.data.user_exists || error.response.data.data.company_exists || error.response.data.data.email)
      if (!hasErrorMessage) {
        const feedback = {
          type: 'error',
          title: 'Houve um erro ao criar a sua conta',
          text: 'Tente novamente mais tarde'
        }
        dispatch(FEEDBACK, feedback)
      }
      return hasErrorMessage ? error.response.data : false
    }
  }
}

export default { actions }
