import store from '@/store'
import * as types from '@/store/types'
import { print } from 'graphql/language/printer'

/**
 * Request GraphQL.
 * @param {string} query
 * @param {Object.<string, *>} params
 * @returns {Response}
 */

export default async (query, params = {}, isGeneral = false) => {
  const url = isGeneral
    ? process.env.generalGraphqlUrl
    : process.env.graphqlUrl
  const { token } = store().getters[types.AUTH]
  const config = {
    method: 'POST',
    body: JSON.stringify({ query: print(query), params }),
    headers: {
      'Content-Type': 'application/json',
      ...token && { Authorization: `Bearer ${token}` }
    }
  }
  const response = await fetch(url, config)
  const { data, message } = await response.json()
  if (message === 'CompanyUser not found') {
    window.location.reload()
    return store().dispatch(types.AUTH_LOGOUT)
  }
  return data
}
