
import store, { createInitialState } from '@/store'
import * as types from '@/store/types'

const actions = {
  [types.CONFIG_CLEAR_STORE]: () => {
    const initialState = createInitialState()
    store().replaceState({ ...initialState })
    localStorage.clear()
  }
}

export default { actions }
