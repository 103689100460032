import * as types from '@/store/types'
import decode from 'jwt-decode'
import moment from 'moment'
import axios from 'axios'
import rest from '@/modules/rest'

const restUrl = process.env.restUrl
const authUrl = process.env.authUrl
const rememberUrl = process.env.rememberUrl
const checkTokenUrl = process.env.checkTokenUrl

const state = {
  token: '',
  expiresAt: 0,
  credentials: { document: '', password: '' },
  clubUrl: '',
  userId: '',
  companyId: '',
  changeToCompany: {}
}

const getters = {
  [types.AUTH]: (state) => {
    const token = state.token
    const expiresAt = state.expiresAt
    const companyId = state.companyId

    return { token, expiresAt, companyId }
  },

  [types.AUTH_CHECK]: (state) => {
    const isUnexpired = !!state.token && (+state.expiresAt > Date.now())

    return isUnexpired
  },

  [types.AUTH_CREDENTIALS]: ({ credentials: { birthday, ...credentials } }) => {
    return {
      ...credentials,
      ...(birthday
        ? { birthday: moment(birthday).format('DD/MM/YYYY') }
        : { })
    }
  },

  [types.AUTH_ACCESS_CLUB]: ({ clubUrl }) => clubUrl,

  [types.CHANGE_TO_COMPANY]: ({ changeToCompany }) => changeToCompany
}

const mutations = {
  [types.GET_USER_ID]: (state, payload) => {
    state.userId = payload
  },

  [types.AUTH]: (state, payload) => {
    state.token = payload.token
    state.expiresAt = payload.expiresAt
    state.companyId = payload.company_id
  },

  [types.AUTH_LOGOUT]: (state) => {
    state.token = ''
    state.expiresAt = 0
  },

  [types.AUTH_CREDENTIALS]: (state, payload) => {
    state.credentials = { ...payload }
  },

  [types.AUTH_ACCESS_CLUB]: (state, url) => { state.clubUrl = url },

  [types.CHANGE_TO_COMPANY]: (state, payload) => {
    state.changeToCompany = payload
  }
}

const actions = {
  [types.AUTH_DECODE]: ({ commit }, { token }) => {
    const {
      exp,
      name,
      sub: id,
      document,
      company_id,
      company_logo
    } = decode(token) || {}
    try {
      commit(types.GET_USER_ID, { id })
      commit(types.CONFIG_USER, { id, name, document })
      commit(types.CONFIG_COMPANY_ID, { company_id, company_logo })
      commit(types.AUTH, { token, expiresAt: exp * 1000, company_id })
    } catch (error) {
      console.log(error)
    }
  },

  [types.AUTH_LOGIN]: async ({ commit, dispatch }, variables) => {
    try {
      const { data: { data } = {} } = await axios.post(authUrl, variables)

      if (Array.isArray(data) && data.length) {
        commit(types.CONFIG_COMPANY_LIST, data)
        commit(types.AUTH_CREDENTIALS, { ...variables })
      } else {
        dispatch(types.AUTH_DECODE, { token: data })
      }

      return data
    } catch ({ response: { data: { data } } }) {
      return { error: data }
    }
  },

  [types.CHANGE_ACCOUNT]: async ({ state, dispatch, commit, getters }, account) => {
    const companies = getters[types.CONFIG_COMPANY_LIST]

    const changeTo = companies.find(comp => comp.id === account)

    const url = `${restUrl}/company/${state.companyId}/change-company/${changeTo.id}`

    const { data: { data } } = await rest().get(url)

    dispatch(types.AUTH_DECODE, { token: data })
    commit(types.CHANGE_TO_COMPANY, changeTo)
  },

  [types.AUTH_LOGOUT]: ({ commit, dispatch }) => {
    commit(types.CONFIG_RESET_PERSONALIZATION)
    dispatch(types.CONFIG_CLEAR_STORE)
    commit(types.AUTH_LOGOUT)
  },

  [types.AUTH_ACTIVATE]: async ({ commit }, payload) => {
    try {
      const url = `${restUrl}/activate`
      const { data: { data } } = await axios.post(url, { ...payload })
      const { verify, companies } = data

      if (verify && companies.length) {
        commit(types.CONFIG_COMPANIES_ACTIVE, [...companies])
      } else if (!verify && companies.length) {
        commit(types.CONFIG_COMPANIES_ENABLED, [...companies])
      }

      commit(types.AUTH_CREDENTIALS, { ...payload })

      return data
    } catch ({ response: { data: { data } } }) {
      return data
    }
  },

  [types.AUTH_ACTIVATE_CONFIRM]: async ({ commit, getters }, { password }) => {
    try {
      const url = `${restUrl}/activate/confirm`
      const credentials = { ...getters[types.AUTH_CREDENTIALS], password }
      const { data: { data } } = await axios.post(url, credentials)
      const { user, companies } = data

      if (companies.length) {
        commit(types.CONFIG_COMPANIES_ENABLED, [...companies])
      }

      commit(types.AUTH_CREDENTIALS, { ...user, ...credentials })

      return data
    } catch ({ response: { data: { data } } }) {
      return data
    }
  },

  [types.AUTH_ACTIVATE_COMPANY]: async ({ dispatch }, { company, ...payload }) => {
    try {
      if (!(payload.avatar instanceof File)) {
        delete payload.avatar
      }

      const formData = new FormData()
      Object.keys(payload)
        .filter(key => !!payload[key])
        .forEach(key => formData.append(key, payload[key]))

      const url = `${restUrl}/activate/${company}/perform`
      const { data: { data: token } } = await axios.post(url, formData)
      const data = { token }

      dispatch(types.AUTH_DECODE, data)
      return data
    } catch ({ response: { data: { data } } }) {
      const text = 'Não foi possível ativar a sua conta'
      dispatch(types.FEEDBACK, { type: 'error', text })
      return data || { error: 11 }
    }
  },

  [types.AUTH_LOGIN_COMPANY]: async ({ commit, dispatch, getters }, { company }) => {
    try {
      const credentials = getters[types.AUTH_CREDENTIALS]

      const { data: { data } } = await axios.post(`${authUrl}/${company}`, credentials)

      const [user, token] = data

      dispatch(types.AUTH_DECODE, token)
      commit(types.CONFIG_USER, user)

      return data
    } catch (error) {
      return { error }
    }
  },

  [types.AUTH_RESET_PASSWORD]: async ({ dispatch }, { token, ...variables }) => {
    try {
      const { data } = await axios.post(`${rememberUrl}/${token}`, variables)
      return data
    } catch (err) {
      const text = 'Não foi possível alterar sua senha'
      dispatch(types.FEEDBACK, { type: 'error', text })
      return false
    }
  },

  [types.AUTH_REMEMBER]: async ({ commit }, variables) => {
    try {
      const { data: { email } } = await axios.post(rememberUrl, variables)
      return { email, status: true }
    } catch ({ response: { data: { data } } }) {
      return { status: false }
    }
  },

  [types.AUTH_CHECK_TOKEN]: async ({ commit, dispatch }, token) => {
    try {
      const { data } = await axios.post(checkTokenUrl, { token })
      return data.data
    } catch ({ response: { data: { data } } }) {
      return data
    }
  },

  [types.AUTH_ACCESS_CLUB]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const endPoint = `/company/${companyId}/club-login`

    try {
      const { data: { data: { url } } } = await rest().get(endPoint)

      commit(types.AUTH_ACCESS_CLUB, url)
    } catch (error) {
      return false
    }
  }
}

export default { state, getters, mutations, actions }
