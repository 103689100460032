import * as types from '@/store/types'
import category_icons from '@/content/categoryIconsList'
import moment from 'moment'
import rest from '@/modules/rest'

const state = {
  loading: false,
  start_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  end_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  activeAndEnabledUsers: {},
  accountsActivatedInThePeriod: {},
  acessesInThePeriod: {},
  usersPerAge: {},
  mainInterests: {},
  usersPerGender: {},
  usersPerPlatform: {},
  userRegistrationInformation: {},
  citiesWithMostUsers: {},
  usersPerMobileBranding: {}
}

const getters = {
  [types.DASH_LOADING]: ({ loading }) => loading,
  [types.DASH_START_DATE]: ({ start_date }) => start_date,
  [types.DASH_END_DATE]: ({ end_date }) => end_date,
  [types.DASH_DATES]: (state) => {
    return {
      start_date: state.start_date,
      end_date: state.end_date
    }
  },
  [types.DASH_ACTIVE_ENABLED_USERS]: ({ activeAndEnabledUsers }) => (activeAndEnabledUsers),
  [types.DASH_ACCOUNTS_ACTIVATED_IN_THE_PERIOD]: ({ accountsActivatedInThePeriod }) => accountsActivatedInThePeriod,
  [types.DASH_ACESSES_IN_THE_PERIOD]: ({ acessesInThePeriod }) => acessesInThePeriod,
  [types.DASH_USERS_PER_AGE]: ({ usersPerAge }) => {
    const data = {
      title: 'Usuários por idade',
      info: {
        title: 'Usuários por idade',
        text: 'Porcentagem de usuários ativos por grupos de faixa etária. '
      },
      ...usersPerAge
    }

    return data
  },
  [types.DASH_INTERESTS_WITH_MOST_USERS]: ({ mainInterests }) => {
    if (mainInterests.data.length === 0) return mainInterests
    const finalData = mainInterests.data.map(interest => {
      const categoryIcon = category_icons.find(category => category.name === interest.name)

      return {
        ...interest,
        icon: categoryIcon ? categoryIcon.icon : 'kaledo'
      }
    })
    return {
      ...mainInterests,
      data: finalData
    }
  },
  [types.DASH_USERS_PER_GENDER]: ({usersPerGender}) => {
    const data = {
      title: 'Usuários por gênero',
      info: {
        title: 'Usuários por gênero',
        text: 'Quantidade em percentual de usuários ativos por gênero no seu clube.'
      },
      ...usersPerGender
    }

    return data
  },
  [types.DASH_USERS_PER_PLATFORM]: ({usersPerPlatform}) => {
    const data = {
      title: 'Plataformas mais acessadas',
      info: {
        title: 'Dispositivos mais acessados',
        text: 'Quantidade em percentual de acessos recebidos no seu clube por tipo de dispositivo.'
      },
      ...usersPerPlatform
    }

    return data
  },
  [types.DASH_USER_REGISTRATION_INFORMATION]: ({userRegistrationInformation}) => {
    const data = {
      title: 'Informações de cadastro',
      info: {
        title: 'Cidades com mais usuários ativos',
        text: 'Listagem de cidades em ordem decrescente de número de usuários ativos no clube no período.'
      },
      ...userRegistrationInformation
    }

    return data
  },
  [types.DASH_CITIES_WITH_MOST_USERS]: ({citiesWithMostUsers}) => citiesWithMostUsers,
  [types.DASH_USERS_PER_MOBILE_BRANDING]: ({usersPerMobileBranding}) => {
    const data = {
      title: 'Dispositivos mais utilizados',
      info: {
        title: 'Dispositivos mais utilizados',
        text: 'Quantidade em percentual de acessos recebidos no seu clube por tipo de dispositivo.'
      },
      ...usersPerMobileBranding
    }

    return data
  }
}

const mutations = {
  [types.DASH_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [types.DASH_DATES]: (state, payload) => {
    state.start_date = payload.start_date
    state.end_date = payload.end_date
  },
  [types.DASH_ACTIVE_ENABLED_USERS]: (state, payload) => {
    state.activeAndEnabledUsers = payload
  },
  [types.DASH_ACCOUNTS_ACTIVATED_IN_THE_PERIOD]: (state, payload) => {
    const infos = [
      {
        title: 'Contas ativadas no período',
        text: 'Quantidade total de contas que tiveram seus status atualizados para Ativo no período.'
      },
      {
        title: 'Usuários ativos no período',
        text: 'Quantidade total de usuários cujas contas estão com status Ativo no período.'
      },
      {
        title: 'Usuários habilitados no período',
        text: 'Quantidade total de usuários cujas contas estão com status Habilitado no período.'
      }
    ]
    const newTabs = []
    payload.tabs.forEach((tab, index) => {
      newTabs.push({
        info: infos[index],
        ...tab
      })
    })
    state.accountsActivatedInThePeriod = {
      ...payload,
      tabs: newTabs
    }
  },
  [types.DASH_ACESSES_IN_THE_PERIOD]: (state, payload) => {
    const infos = [
      {
        title: 'Quantidade de acessos no período',
        text: 'Quantidade total de vezes em que o Clube foi acessado pelos seus usuários.'
      },
      {
        title: 'Quantidade de usuários que acessaram',
        text: 'Quantidade total de usuários que fizeram ao menos um acesso no clube no período.'
      },
      {
        title: 'Média de sessões abertas por usuário',
        text: 'Quantidade média de sessões que foram abertas por usuários no clube no período.'
      },
      {
        title: 'Tempo médio de sessões dos usuários',
        text: 'Tempo médio que os usuários utilizaram em sessões abertas no clube no período.'
      }
    ]
    const newTabs = []
    payload.tabs.forEach((tab, index) => {
      const isTimeValue = index === (payload.tabs.length - 1)
      newTabs.push({
        info: infos[index],
        isTimeValue,
        ...tab
      })
    })
    state.acessesInThePeriod = {
      ...payload,
      tabs: newTabs
    }
  },
  [types.DASH_USERS_PER_AGE]: (state, payload) => {
    state.usersPerAge = payload
  },
  [types.DASH_INTERESTS_WITH_MOST_USERS]: (state, payload) => {
    state.mainInterests = payload
  },
  [types.DASH_USERS_PER_GENDER]: (state, payload) => {
    state.usersPerGender = payload
  },
  [types.DASH_USERS_PER_PLATFORM]: (state, payload) => {
    state.usersPerPlatform = payload
  },
  [types.DASH_USER_REGISTRATION_INFORMATION]: (state, payload) => {
    state.userRegistrationInformation = payload
  },
  [types.DASH_CITIES_WITH_MOST_USERS]: (state, payload) => {
    state.citiesWithMostUsers = payload
  },
  [types.DASH_USERS_PER_MOBILE_BRANDING]: (state, payload) => {
    state.usersPerMobileBranding = payload
  }
}

const actions = {
  [types.DASH_ACTIVE_ENABLED_USERS]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const endPoint = `company/${companyId}/dashboard/totalOfEnabledAndActivatedUsers`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_ACTIVE_ENABLED_USERS, res.data.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_ACCOUNTS_ACTIVATED_IN_THE_PERIOD]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/totalOfUsersSpineGraph?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_ACCOUNTS_ACTIVATED_IN_THE_PERIOD, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_ACESSES_IN_THE_PERIOD]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/accessesInThePeriod?start_date=${startDate}&end_date=${endDate}`
    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_ACESSES_IN_THE_PERIOD, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_USERS_PER_AGE]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/usersPerAge?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_USERS_PER_AGE, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_INTERESTS_WITH_MOST_USERS]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/interestsWithMostUsers?start_date=${startDate}&end_date=${endDate}`
    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_INTERESTS_WITH_MOST_USERS, {
          ...res.data
        })
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_USERS_PER_GENDER]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/usersPerGender?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_USERS_PER_GENDER, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },

  [types.DASH_USERS_PER_PLATFORM]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/usersPerPlatform?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_USERS_PER_PLATFORM, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_USER_REGISTRATION_INFORMATION]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/userRegistrationInformation?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_USER_REGISTRATION_INFORMATION, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_CITIES_WITH_MOST_USERS]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/citiesWithMostUsers?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_CITIES_WITH_MOST_USERS, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_USERS_PER_MOBILE_BRANDING]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/usersPerMobileBranding?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_USERS_PER_MOBILE_BRANDING, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  }
}

export default { state, getters, mutations, actions }
