import * as types from '@/store/types'

const middleware = async ({ store, route, redirect, error }) => {
  const token = route.query['token'] || ''
  const isTokenValid = await store.dispatch(types.AUTH_CHECK_TOKEN, token)

  if (!isTokenValid) {
    redirect({ name: 'login' })
    const text = 'Token inválido'
    store.dispatch(types.FEEDBACK, { type: 'error', text })
  }
}

export default middleware
