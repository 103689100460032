import * as types from '@/store/types'
import request from '@/modules/request'
import store from '@/store'

import GetAddressPagination from '@/store/address/GetAddressPagination'

const state = {
  pagination: { list: [] }
}

const getters = {
  [types.ADDRESS_PAGINATE]: ({ pagination }) => ({ ...pagination })
}

const mutations = {
  [types.ADDRESS_PAGINATE]: (state, payload) => {
    const list = [ ...state.pagination.list, ...payload.list ]
    state.pagination = { ...payload, list }
  },
  [types.ADDRESS_FILTER]: (state, payload) => {
    state.pagination = { ...payload }
  },
  [types.ADDRESS_CLEAR]: (state) => {
    state.pagination = { list: [] }
  }
}

const actions = {
  [types.ADDRESS_PAGINATE]: async ({ commit }, variables) => {
    const { pagination } = await request(GetAddressPagination, variables)
    commit(types.ADDRESS_PAGINATE, pagination)
  },
  [types.ADDRESS_FILTER]: async ({ commit }, variables) => {
    const { pagination } = await request(GetAddressPagination, variables)
    commit(types.ADDRESS_FILTER, pagination)
  }
}

export default { state, getters, mutations, actions }
