/**
 * @template T
 * @param {string} property
 * @param {T} value
 * @returns {Object.<string, T>}
 */
const REDUCE_DEFAULT_Λ = (property, value) => ({
  [property]: value
})

/**
 * Usa a técnica de redução com as entradas de um objeto.
 * @template T
 * @param {object} object
 * @param {function(string, T): Object.<string, T>} λ
 * @returns {Object.<string, T>}
 */
export const reduce = (object, λ = REDUCE_DEFAULT_Λ) => {
  const entries = Object.entries(object)
  const reduction = entries.reduce((object, [ property, value ]) => {
    const entry = λ(property, value) || {}
    return Object.assign({}, object, entry)
  }, {})
  return reduction
}

export const hasProperty = (object, property) => {
  const result = Object.prototype.hasOwnProperty.call(object, property)
  return result
}

export const assign = (type, ...objects) => {
  const [ comparison ] = (type === 'right') ? [ ...objects ].reverse() : objects
  const [ objectA, ...objectsB ] = objects

  if (objectsB.length < 1)
    return objectA

  const objectB = objectsB.length === 1 ? objectsB[0] : assign(type, ...objectsB)
  const reductionA = reduce(objectA, (property, value) => {
    const entry = hasProperty(comparison, property) ? { [property]: value } : null
    return entry
  })
  const reductionB = reduce(objectB, (property, value) => {
    const isValid = value !== undefined && hasProperty(comparison, property)
    const entry = isValid ? { [property]: value } : null
    return entry
  })
  const result = Object.assign({}, reductionA, reductionB)
  return result
}

export const equals = (obj1, obj2) => {
  const isObject = (data) => typeof (data) === 'object'
  const hasOwnProperty = (obj1, obj2, key) => obj1.hasOwnProperty(key) === obj2.hasOwnProperty(key)

  return Object.keys(obj1).every(key => {
    if (!hasOwnProperty(obj1, obj2, key)) return false

    return isObject(obj1[key])
      ? equals(obj1[key], obj2[key])
      : obj1[key] === obj2[key]
  })
}
