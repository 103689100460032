import * as types from '@/store/types'
import rest from '@/modules/rest'
import { FEEDBACK } from '@/store/types'

const state = {
  distribution_step: 1,
  distribution_order: {},
  distribution_orders: []
}

const getters = {
  [types.DISTRIBUTION_STEP]: ({ distribution_step }) => distribution_step,
  [types.DISTRIBUTION_ORDER]: ({ distribution_order }) => distribution_order,
  [types.DISTRIBUTION_ORDERS]: ({ distribution_orders }) => distribution_orders
}

const mutations = {
  [types.DISTRIBUTION_STEP]: (state, payload) => {
    state.distribution_step = payload
  },
  [types.DISTRIBUTION_ORDER]: (state, payload) => {
    state.distribution_order = payload
  },
  [types.DISTRIBUTION_ORDERS]: (state, payload) => {
    state.distribution_orders = payload
  }
}

const actions = {
  [types.GET_DISTRIBUTION_ORDER_DETAILS]: async ({ dispatch, commit }, payload) => {
    const id = payload

    try {
      const engagementDetails = await rest().get(`/engagement/${id}`)
      commit(types.DISTRIBUTION_ORDER, engagementDetails.data.data)
      return true
    } catch (e) {
      const feedback = {
        type: 'error',
        title: 'Houve um erro ao carregar informações do pedido',
        text: 'Tente novamente mais tarde'
      }
      dispatch(types.FEEDBACK, feedback)
      return false
    }
  },
  [types.GET_DISTRIBUTION_ORDERS]: async ({ commit }, variables) => {
    const orders = await rest().get('/engagement', {
      params: {
        ...variables
      }
    })
    commit(types.DISTRIBUTION_ORDERS, orders.data)
  },
  [types.CREATE_DISTRIBUTION_ORDER]: async ({ dispatch }, payload) => {
    try {
      const res = await rest().post('/engagement', payload)
      if (res && res.data && res.data.success) {
        const feedback = {
          type: 'success',
          title: 'Pedido criado com sucesso',
          text: ''
        }
        dispatch(FEEDBACK, feedback)
      } else {
        const feedback = {
          type: 'error',
          title: 'Houve um erro ao criar o seu pedido',
          text: 'Tente novamente mais tarde'
        }
        dispatch(FEEDBACK, feedback)
      }
      return res.data.success
    } catch (e) {
      const feedback = {
        type: 'error',
        title: 'Houve um erro ao criar o seu pedido',
        text: 'Tente novamente mais tarde'
      }
      dispatch(FEEDBACK, feedback)
      return false
    }
  },
  [types.CANCEL_DISTRIBUTION_ORDER]: async ({ dispatch }, payload) => {
    const { id } = payload
    const res = await rest().put(`/engagement/${id}`)

    if (res && res.data && res.data.success) {
      dispatch(FEEDBACK, {
        type: 'success',
        title: 'Pedido cancelado com sucesso!',
        text: ''
      })
    } else {
      dispatch(FEEDBACK, {
        type: 'error',
        title: 'Ocorreu um problema ao cancelar o pedido',
        text: ''
      })
    }
    return res
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
