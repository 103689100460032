import * as types from '@/store/types'
import request from '@/modules/request'
import rest from '@/modules/rest'

import GetUser from '@/store/user/GetUser'
import GetUsersPagination from '@/store/user/GetUsersPagination'
import ToggleUserStatus from '@/store/user/ToggleUserStatus'
import ToggleManagerStatus from '@/store/user/ToggleManagerStatus'
import PatchUser from '@/store/user/PatchUser'
import DeleteUser from '@/store/user/DeleteUser'
import UsersBulkDelete from '@/store/user/UsersBulkDelete'

const restUrl = process.env.restUrl

const state = {
  user: {},
  users: {},
  userCount: { active_users: 0, total_users: 0 },
  pagination: { users: [] },
  userWalletOrders: []
}

const getters = {
  [types.USER]: ({ user }) => ({ ...user }),
  [types.USER_COUNT]: ({ userCount }) => ({ ...userCount }),
  [types.USERS_PAGINATION]: ({ pagination }) => ({ ...pagination }),
  [types.USER_WALLET_ORDERS]: ({ userWalletOrders }) => userWalletOrders
}

const mutations = {
  [types.USER]: (state, { birthday, ...payload }) => {
    state.user = {
      ...payload,
      ...(birthday
        ? { birthday: new Date(birthday * 1000).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' }) }
        : {})
    }
  },

  [types.USER_COUNT]: (state, payload) => {
    state.userCount = { ...payload }
  },

  [types.USERS_PAGINATION]: (state, payload) => {
    const users = [...state.pagination.users, ...payload.users]

    state.pagination = { ...payload, users }
  },

  [types.USERS_SEARCH]: (state, payload) => {
    // verificar se busca deve ficar em sincronia com o filtro
    const users = [...state.pagination.users, ...payload.users]

    state.pagination = { ...payload, ...users }
  },

  [types.USER_TOGGLE_STATUS]: (state, variables) => {
    const { id, status } = variables
    const changeStatus = user => {
      if (user.id === id) user.status = status

      return user
    }

    const users = [...state.pagination.users.map(changeStatus)]

    state.pagination = { ...state.pagination, users }
    if (status === 1) ++state.userCount.active_users
    else --state.userCount.active_users
  },

  [types.USER_MANAGER_TOGGLE_STATUS]: (state, variables) => {
    const { id, status } = variables

    const roles = status
      ? state.user.roles.push('panel_manager')
      : state.user.roles = []

    state.user = { ...state.user, ...roles }
  },

  [types.USER_PATCH]: (state, variables) => {
    const { user, id } = variables

    const changeUser = u => {
      if (u.id === id) u = user

      return u
    }

    const users = [...state.pagination.users.map(changeUser)]

    state.pagination = { ...state.pagination, users }
  },

  [types.USER_DELETE]: (state, id) => {
    const users = [...state.pagination.users.filter(u => u.id !== id)]

    state.pagination = { ...state.pagination, users }
  },

  [types.USERS_BULK_DELETE]: (state, removedUsers) => {
    if (removedUsers.allUsers) {
      const users = state.pagination.users.filter(user => user.id === state.user.id)
      state.pagination.users = users
      state.pagination = { ...state.pagination, users }
    } else {
      const usersIdToRemove = removedUsers.usersId.map(userId => {
        const userToRemove = state.pagination.users.find(user => user.id === userId)
        if (!removedUsers.notRemoved.includes(userToRemove.document)) return userToRemove.id
      })
      const users = state.pagination.users.filter(user => !usersIdToRemove.includes(user.id))
      state.pagination = { ...state.pagination, users }
    }
  },

  [types.USERS_CLEAR]: (state) => {
    state.userCount = { active_users: 0, total_users: 0 }
    state.pagination = { users: [] }
    state.user = {}
  },

  [types.USER_PANEL_ONBOARDING]: (state) => {
    state.user.panel_onboarding = true
  },

  [types.USER_WALLET_ORDERS]: (state, orders) => {
    state.userWalletOrders = orders
  }
}

const actions = {
  [types.USER]: async ({ commit }, id) => {
    const response = await request(GetUser, id)

    if (response) commit(types.USER, response.user)
  },
  [types.USERS_PAGINATION]: async ({ commit }, filters) => {
    const { pagination } = await request(GetUsersPagination, { ...filters })

    commit(types.USERS_PAGINATION, pagination)
  },

  [types.USERS_SEARCH]: async ({ commit }, filters) => {
    const { pagination } = await request(GetUsersPagination, filters)

    commit(types.USERS_SEARCH, pagination)
  },

  [types.USER_TOGGLE_STATUS]: async ({ commit, dispatch, state }, variables) => {
    try {
      const { status } = await request(ToggleUserStatus, variables)

      if (status) {
        commit(types.USER_TOGGLE_STATUS, variables)
        const msg = { type: 'success', text: 'O status do usuário foi alterado com sucesso!' }
        dispatch(types.FEEDBACK, msg)
        if (variables.status !== 2) {
          const id = state.user && state.user.id ? state.user.id : variables.id
          dispatch(types.USER, { id })
        }

        return variables.id
      }
    } catch (e) {
      const msg = { type: 'error', text: 'Ocorreu um problema ao alterar o status do usuário' }
      dispatch(types.FEEDBACK, msg)
    }
  },

  [types.USER_MANAGER_TOGGLE_STATUS]: async ({ commit, dispatch }, variables) => {
    try {
      await request(ToggleManagerStatus, variables)
      commit(types.USER_MANAGER_TOGGLE_STATUS, variables)
      const msg = { type: 'success', text: 'Permissão do usuário foi alterada com sucesso!' }
      dispatch(types.FEEDBACK, msg)

      return variables.id
    } catch (e) {
      const msg = { type: 'error', text: 'Ocorreu um problema ao alterar o status do gestor' }
      dispatch(types.FEEDBACK, msg)
    }
  },

  [types.USER_PATCH]: async ({ commit, dispatch }, variables) => {
    try {
      const { birthday } = (variables || {}).user
      const splitBirthday = (birthday || '').split('/')

      const user = {
        ...variables,
        user: {
          ...variables.user,
          birthday: splitBirthday.length > 1
            ? splitBirthday.reverse().join('-')
            : birthday
        }
      }

      const { userUpdated } = await request(PatchUser, user)
      if (userUpdated) {
        commit(types.USER_PATCH, variables)

        const msg = { type: 'success', text: 'O usuário foi alterado com sucesso!' }
        dispatch(types.FEEDBACK, msg)

        return variables.id
      }
    } catch (e) {
      const msg = { type: 'error', text: 'Ocorreu um problema ao alterar o usuário' }
      dispatch(types.FEEDBACK, msg)
    }
  },

  [types.USER_DELETE]: async ({ commit, dispatch }, id) => {
    try {
      const { userDeleted } = await request(DeleteUser, id)
      if (userDeleted) {
        commit(types.USER_DELETE, id.id)

        const msg = { type: 'success', text: 'O usuário foi excluído com sucesso!' }
        dispatch(types.FEEDBACK, msg)
      }
    } catch (e) {
      const msg = { type: 'error', text: 'Ocorreu um problema ao excuir o usuário' }
      dispatch(types.FEEDBACK, msg)
    }
  },

  [types.USER_COUNT]: async ({ commit, getters }) => {
    const company = getters[types.CONFIG_COMPANY_ID]
    const { data: { data } } = await rest().get(`${restUrl}/company/${company}/dashboard/user/data`)
    let { common_data: { active_users, enabled_users, total_users } } = data
    if (active_users === '') active_users = 0
    commit(types.USER_COUNT, { active_users: parseInt(active_users), total_users })
  },

  [types.USERS_CLEAR]: ({ commit }) => {
    commit(types.USERS_CLEAR)
  },

  [types.USERS_BULK_DELETE]: async ({ commit, dispatch }, payload) => {
    try {
      const response = await request(UsersBulkDelete, payload)
      commit(types.USERS_BULK_DELETE, { usersId: payload.users_id, notRemoved: response.userBulkDelete.notRemoved, allUsers: payload.allUsers })
      dispatch(types.USER_COUNT)
      return response.userBulkDelete
    } catch (e) {
      const msg = { type: 'error', text: 'Ocorreu um problema ao excuir os usuários' }
      dispatch(types.FEEDBACK, msg)
    }
  },

  [types.USER_PANEL_ONBOARDING]: async ({ commit }) => {
    try {
      await rest().post('user/onboarding')
      commit(types.USER_PANEL_ONBOARDING)
    } catch (err) {
      return err
    }
  },

  [types.USER_BUY_NEW_BALANCE]: async ({ dispatch }, payload) => {
    try {
      await rest().post('/statement', payload)
      dispatch(types.FEEDBACK, { type: 'success', text: 'Pedido realizado com sucesso!' })
      return { success: true }
    } catch (err) {
      dispatch(types.FEEDBACK, { type: 'error', text: 'Ocorreu um erro, Tente novamente' })
      return { success: false }
    }
  },

  [types.USER_WALLET_ORDERS]: async ({ commit, dispatch }, filters) => {
    try {
      let route = '/statement'
      if (filters) route = `/statement?${filters}`
      const response = await rest().get(route)
      commit(types.USER_WALLET_ORDERS, response.data)
      return { success: true }
    } catch (err) {
      dispatch(types.FEEDBACK, { type: 'error', text: 'Ocorreu um erro, Tente novamente' })
      return { success: false }
    }
  },

  [types.USER_WALLET_ORDER_DETAIL]: async ({ dispatch }, orderId) => {
    try {
      const response = await rest().get(`/statement/${orderId}`)
      return response.data.data
    } catch (err) {
      dispatch(types.FEEDBACK, { type: 'error', text: 'Ocorreu um erro, Tente novamente' })
      return { success: false }
    }
  },

  [types.USER_CANCEL_WALLET_ORDER]: async ({ dispatch }, orderId) => {
    try {
      const response = await rest().patch(`/statement/${orderId}/cancel`)
      dispatch(types.FEEDBACK, { type: 'warning', text: 'Pedido cancelado com sucesso!' })
      return { success: true }
    } catch (err) {
      dispatch(types.FEEDBACK, { type: 'error', text: 'Ocorreu um erro, Tente novamente' })
      return { success: false }
    }
  }
}

export default { state, getters, mutations, actions }
