import * as types from '@/store/types'

const feedbackTemplate = {
  type: undefined,
  title: undefined,
  text: undefined,
  icon: undefined
}

const state = {
  feedback: { ...feedbackTemplate }
}

const getters = {
  [types.FEEDBACK]: ({ feedback}) => feedback
}

const mutations = {
  [types.FEEDBACK]: (state, payload) => {
    state.feedback = { ...payload }
  }
}

const actions = {
  [types.FEEDBACK]: ({ commit }, payload) => {
    commit(types.FEEDBACK, payload)
  },
  [types.FEEDBACK_CLOSE]: ({ commit }) => {
    commit(types.FEEDBACK, feedbackTemplate)
  }
}

export default { state, getters, mutations, actions }
