import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3f2e1568 = () => interopDefault(import('../pages/atalhos/index.vue' /* webpackChunkName: "pages/atalhos/index" */))
const _4c676c4f = () => interopDefault(import('../pages/carteira/index.vue' /* webpackChunkName: "pages/carteira/index" */))
const _8f88470a = () => interopDefault(import('../pages/comunicacao.vue' /* webpackChunkName: "pages/comunicacao" */))
const _8d5c4a84 = () => interopDefault(import('../pages/comunicacao/index.vue' /* webpackChunkName: "pages/comunicacao/index" */))
const _3ed9a612 = () => interopDefault(import('../pages/comunicacao/criar.vue' /* webpackChunkName: "pages/comunicacao/criar" */))
const _0cbe2e57 = () => interopDefault(import('../pages/comunicacao/midias.vue' /* webpackChunkName: "pages/comunicacao/midias" */))
const _62967c10 = () => interopDefault(import('../pages/comunicacao/notificacoes/index.vue' /* webpackChunkName: "pages/comunicacao/notificacoes/index" */))
const _55f4f1f4 = () => interopDefault(import('../pages/comunicacao/notificacoes/criar/index.vue' /* webpackChunkName: "pages/comunicacao/notificacoes/criar/index" */))
const _d5c847b6 = () => interopDefault(import('../pages/comunicacao/notificacoes/detalhes/index.vue' /* webpackChunkName: "pages/comunicacao/notificacoes/detalhes/index" */))
const _eab71c2c = () => interopDefault(import('../pages/comunicacao/notificacoes/criar/_notificationId/index.vue' /* webpackChunkName: "pages/comunicacao/notificacoes/criar/_notificationId/index" */))
const _733ada1b = () => interopDefault(import('../pages/comunicacao/notificacoes/detalhes/_notificationId/index.vue' /* webpackChunkName: "pages/comunicacao/notificacoes/detalhes/_notificationId/index" */))
const _7bb8c074 = () => interopDefault(import('../pages/comunicacao/_id/editar.vue' /* webpackChunkName: "pages/comunicacao/_id/editar" */))
const _0d67f58b = () => interopDefault(import('../pages/configuracao/index.vue' /* webpackChunkName: "pages/configuracao/index" */))
const _b72e6010 = () => interopDefault(import('../pages/crie-sua-conta.vue' /* webpackChunkName: "pages/crie-sua-conta" */))
const _33c52a0e = () => interopDefault(import('../pages/engajamento.vue' /* webpackChunkName: "pages/engajamento" */))
const _4a216e11 = () => interopDefault(import('../pages/engajamento/index.vue' /* webpackChunkName: "pages/engajamento/index" */))
const _4431a06e = () => interopDefault(import('../pages/engajamento/campanhas/index.vue' /* webpackChunkName: "pages/engajamento/campanhas/index" */))
const _42f3d30c = () => interopDefault(import('../pages/engajamento/distribuicao/index.vue' /* webpackChunkName: "pages/engajamento/distribuicao/index" */))
const _adf553d6 = () => interopDefault(import('../pages/engajamento/personalizacao/index.vue' /* webpackChunkName: "pages/engajamento/personalizacao/index" */))
const _7f838487 = () => interopDefault(import('../pages/engajamento/campanhas/nova-campanha/index.vue' /* webpackChunkName: "pages/engajamento/campanhas/nova-campanha/index" */))
const _6dfcc191 = () => interopDefault(import('../pages/engajamento/distribuicao/detalhes/index.vue' /* webpackChunkName: "pages/engajamento/distribuicao/detalhes/index" */))
const _67adcaf5 = () => interopDefault(import('../pages/engajamento/distribuicao/novo-pedido/index.vue' /* webpackChunkName: "pages/engajamento/distribuicao/novo-pedido/index" */))
const _ea8f69c4 = () => interopDefault(import('../pages/engajamento/distribuicao/detalhes/_orderId/index.vue' /* webpackChunkName: "pages/engajamento/distribuicao/detalhes/_orderId/index" */))
const _758acbb6 = () => interopDefault(import('../pages/esqueci-minha-senha.vue' /* webpackChunkName: "pages/esqueci-minha-senha" */))
const _3353a460 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f79c1fba = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _cd88fae6 = () => interopDefault(import('../pages/nova-senha.vue' /* webpackChunkName: "pages/nova-senha" */))
const _2f659b4a = () => interopDefault(import('../pages/parceiros/index.vue' /* webpackChunkName: "pages/parceiros/index" */))
const _5e8aebdd = () => interopDefault(import('../pages/personalizacao/index.vue' /* webpackChunkName: "pages/personalizacao/index" */))
const _54537a16 = () => interopDefault(import('../pages/premiacoes.vue' /* webpackChunkName: "pages/premiacoes" */))
const _a6800490 = () => interopDefault(import('../pages/premiacoes/index.vue' /* webpackChunkName: "pages/premiacoes/index" */))
const _7797d865 = () => interopDefault(import('../pages/premiacoes/detalhes/index.vue' /* webpackChunkName: "pages/premiacoes/detalhes/index" */))
const _3e9353a1 = () => interopDefault(import('../pages/premiacoes/novo-pedido/index.vue' /* webpackChunkName: "pages/premiacoes/novo-pedido/index" */))
const _d2f20692 = () => interopDefault(import('../pages/premiacoes/pedidos/index.vue' /* webpackChunkName: "pages/premiacoes/pedidos/index" */))
const _87f0ba6c = () => interopDefault(import('../pages/premiacoes/detalhes/_orderId/index.vue' /* webpackChunkName: "pages/premiacoes/detalhes/_orderId/index" */))
const _2f84f19a = () => interopDefault(import('../pages/relatorios/index.vue' /* webpackChunkName: "pages/relatorios/index" */))
const _c29af47a = () => interopDefault(import('../pages/selecao-de-conta.vue' /* webpackChunkName: "pages/selecao-de-conta" */))
const _7b8460e9 = () => interopDefault(import('../pages/troca-de-contas.vue' /* webpackChunkName: "pages/troca-de-contas" */))
const _09a6dbde = () => interopDefault(import('../pages/usuarios.vue' /* webpackChunkName: "pages/usuarios" */))
const _c56af83e = () => interopDefault(import('../pages/usuarios/index.vue' /* webpackChunkName: "pages/usuarios/index" */))
const _60de87f9 = () => interopDefault(import('../pages/usuarios/importacao/index.vue' /* webpackChunkName: "pages/usuarios/importacao/index" */))
const _7ed29955 = () => interopDefault(import('../pages/usuarios/importacao/nova.vue' /* webpackChunkName: "pages/usuarios/importacao/nova" */))
const _d47e5a92 = () => interopDefault(import('../pages/usuarios/_userId/index.vue' /* webpackChunkName: "pages/usuarios/_userId/index" */))
const _1d6cafae = () => interopDefault(import('../pages/usuarios/_userId/editar/index.vue' /* webpackChunkName: "pages/usuarios/_userId/editar/index" */))
const _e52f0dae = () => interopDefault(import('../pages/ativacao/ativar-conta.vue' /* webpackChunkName: "pages/ativacao/ativar-conta" */))
const _53d29b9a = () => interopDefault(import('../pages/ativacao/clubes.vue' /* webpackChunkName: "pages/ativacao/clubes" */))
const _2bd35124 = () => interopDefault(import('../pages/ativacao/verificacao.vue' /* webpackChunkName: "pages/ativacao/verificacao" */))
const _c9a5c0c8 = () => interopDefault(import('../pages/carteira/adicionar/index.vue' /* webpackChunkName: "pages/carteira/adicionar/index" */))
const _43e3e886 = () => interopDefault(import('../pages/configuracao/acesso/index.vue' /* webpackChunkName: "pages/configuracao/acesso/index" */))
const _0acce4b2 = () => interopDefault(import('../pages/configuracao/ambiente/index.vue' /* webpackChunkName: "pages/configuracao/ambiente/index" */))
const _4d4acf0d = () => interopDefault(import('../pages/configuracao/bloqueios/index.vue' /* webpackChunkName: "pages/configuracao/bloqueios/index" */))
const _2cfbade4 = () => interopDefault(import('../pages/configuracao/logout/index.vue' /* webpackChunkName: "pages/configuracao/logout/index" */))
const _56a6ed83 = () => interopDefault(import('../pages/parceiros/criar.vue' /* webpackChunkName: "pages/parceiros/criar" */))
const _3d0cea77 = () => interopDefault(import('../pages/parceiros/geral.vue' /* webpackChunkName: "pages/parceiros/geral" */))
const _040328b6 = () => interopDefault(import('../pages/personalizacao/ambientes-internos/index.vue' /* webpackChunkName: "pages/personalizacao/ambientes-internos/index" */))
const _36e170b9 = () => interopDefault(import('../pages/personalizacao/pagina-de-acesso/index.vue' /* webpackChunkName: "pages/personalizacao/pagina-de-acesso/index" */))
const _662956ae = () => interopDefault(import('../pages/relatorios/relatorios/index.vue' /* webpackChunkName: "pages/relatorios/relatorios/index" */))
const _69ac0149 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _35218cbe = () => interopDefault(import('../pages/carteira/detalhes/_orderId/index.vue' /* webpackChunkName: "pages/carteira/detalhes/_orderId/index" */))
const _1fbe9674 = () => interopDefault(import('../pages/ativacao/_company.vue' /* webpackChunkName: "pages/ativacao/_company" */))
const _06a47705 = () => interopDefault(import('../pages/parceiros/_slug/index.vue' /* webpackChunkName: "pages/parceiros/_slug/index" */))
const _eef5ef3c = () => interopDefault(import('../pages/parceiros/_slug/campanhas/index.vue' /* webpackChunkName: "pages/parceiros/_slug/campanhas/index" */))
const _2f59bb98 = () => interopDefault(import('../pages/parceiros/_slug/editar.vue' /* webpackChunkName: "pages/parceiros/_slug/editar" */))
const _a0734aca = () => interopDefault(import('../pages/parceiros/_slug/campanhas/criar.vue' /* webpackChunkName: "pages/parceiros/_slug/campanhas/criar" */))
const _8f562a7c = () => interopDefault(import('../pages/parceiros/_slug/campanhas/_campaign/index.vue' /* webpackChunkName: "pages/parceiros/_slug/campanhas/_campaign/index" */))
const _9890810a = () => interopDefault(import('../pages/parceiros/_slug/campanhas/_campaign/editar.vue' /* webpackChunkName: "pages/parceiros/_slug/campanhas/_campaign/editar" */))
const _25813c45 = () => interopDefault(import('../pages/parceiros/_slug/campanhas/_campaign/unidades.vue' /* webpackChunkName: "pages/parceiros/_slug/campanhas/_campaign/unidades" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/atalhos",
    component: _3f2e1568,
    name: "atalhos"
  }, {
    path: "/carteira",
    component: _4c676c4f,
    name: "carteira"
  }, {
    path: "/comunicacao",
    component: _8f88470a,
    children: [{
      path: "",
      component: _8d5c4a84,
      name: "comunicacao"
    }, {
      path: "criar",
      component: _3ed9a612,
      name: "comunicacao-criar"
    }, {
      path: "midias",
      component: _0cbe2e57,
      name: "comunicacao-midias"
    }, {
      path: "notificacoes",
      component: _62967c10,
      name: "comunicacao-notificacoes"
    }, {
      path: "notificacoes/criar",
      component: _55f4f1f4,
      name: "comunicacao-notificacoes-criar"
    }, {
      path: "notificacoes/detalhes",
      component: _d5c847b6,
      name: "comunicacao-notificacoes-detalhes"
    }, {
      path: "notificacoes/criar/:notificationId",
      component: _eab71c2c,
      name: "comunicacao-notificacoes-criar-notificationId"
    }, {
      path: "notificacoes/detalhes/:notificationId",
      component: _733ada1b,
      name: "comunicacao-notificacoes-detalhes-notificationId"
    }, {
      path: ":id/editar",
      component: _7bb8c074,
      name: "comunicacao-id-editar"
    }]
  }, {
    path: "/configuracao",
    component: _0d67f58b,
    name: "configuracao"
  }, {
    path: "/crie-sua-conta",
    component: _b72e6010,
    name: "crie-sua-conta"
  }, {
    path: "/engajamento",
    component: _33c52a0e,
    children: [{
      path: "",
      component: _4a216e11,
      name: "engajamento"
    }, {
      path: "campanhas",
      component: _4431a06e,
      name: "engajamento-campanhas"
    }, {
      path: "distribuicao",
      component: _42f3d30c,
      name: "engajamento-distribuicao"
    }, {
      path: "personalizacao",
      component: _adf553d6,
      name: "engajamento-personalizacao"
    }, {
      path: "campanhas/nova-campanha",
      component: _7f838487,
      name: "engajamento-campanhas-nova-campanha"
    }, {
      path: "distribuicao/detalhes",
      component: _6dfcc191,
      name: "engajamento-distribuicao-detalhes"
    }, {
      path: "distribuicao/novo-pedido",
      component: _67adcaf5,
      name: "engajamento-distribuicao-novo-pedido"
    }, {
      path: "distribuicao/detalhes/:orderId",
      component: _ea8f69c4,
      name: "engajamento-distribuicao-detalhes-orderId"
    }]
  }, {
    path: "/esqueci-minha-senha",
    component: _758acbb6,
    name: "esqueci-minha-senha"
  }, {
    path: "/login",
    component: _3353a460,
    name: "login"
  }, {
    path: "/logout",
    component: _f79c1fba,
    name: "logout"
  }, {
    path: "/nova-senha",
    component: _cd88fae6,
    name: "nova-senha"
  }, {
    path: "/parceiros",
    component: _2f659b4a,
    name: "parceiros"
  }, {
    path: "/personalizacao",
    component: _5e8aebdd,
    name: "personalizacao"
  }, {
    path: "/premiacoes",
    component: _54537a16,
    children: [{
      path: "",
      component: _a6800490,
      name: "premiacoes"
    }, {
      path: "detalhes",
      component: _7797d865,
      name: "premiacoes-detalhes"
    }, {
      path: "novo-pedido",
      component: _3e9353a1,
      name: "premiacoes-novo-pedido"
    }, {
      path: "pedidos",
      component: _d2f20692,
      name: "premiacoes-pedidos"
    }, {
      path: "detalhes/:orderId",
      component: _87f0ba6c,
      name: "premiacoes-detalhes-orderId"
    }]
  }, {
    path: "/relatorios",
    component: _2f84f19a,
    name: "relatorios"
  }, {
    path: "/selecao-de-conta",
    component: _c29af47a,
    name: "selecao-de-conta"
  }, {
    path: "/troca-de-contas",
    component: _7b8460e9,
    name: "troca-de-contas"
  }, {
    path: "/usuarios",
    component: _09a6dbde,
    children: [{
      path: "",
      component: _c56af83e,
      name: "usuarios"
    }, {
      path: "importacao",
      component: _60de87f9,
      name: "usuarios-importacao"
    }, {
      path: "importacao/nova",
      component: _7ed29955,
      name: "usuarios-importacao-nova"
    }, {
      path: ":userId",
      component: _d47e5a92,
      name: "usuarios-userId"
    }, {
      path: ":userId/editar",
      component: _1d6cafae,
      name: "usuarios-userId-editar"
    }]
  }, {
    path: "/ativacao/ativar-conta",
    component: _e52f0dae,
    name: "ativacao-ativar-conta"
  }, {
    path: "/ativacao/clubes",
    component: _53d29b9a,
    name: "ativacao-clubes"
  }, {
    path: "/ativacao/verificacao",
    component: _2bd35124,
    name: "ativacao-verificacao"
  }, {
    path: "/carteira/adicionar",
    component: _c9a5c0c8,
    name: "carteira-adicionar"
  }, {
    path: "/configuracao/acesso",
    component: _43e3e886,
    name: "configuracao-acesso"
  }, {
    path: "/configuracao/ambiente",
    component: _0acce4b2,
    name: "configuracao-ambiente"
  }, {
    path: "/configuracao/bloqueios",
    component: _4d4acf0d,
    name: "configuracao-bloqueios"
  }, {
    path: "/configuracao/logout",
    component: _2cfbade4,
    name: "configuracao-logout"
  }, {
    path: "/parceiros/criar",
    component: _56a6ed83,
    name: "parceiros-criar"
  }, {
    path: "/parceiros/geral",
    component: _3d0cea77,
    name: "parceiros-geral"
  }, {
    path: "/personalizacao/ambientes-internos",
    component: _040328b6,
    name: "personalizacao-ambientes-internos"
  }, {
    path: "/personalizacao/pagina-de-acesso",
    component: _36e170b9,
    name: "personalizacao-pagina-de-acesso"
  }, {
    path: "/relatorios/relatorios",
    component: _662956ae,
    name: "relatorios-relatorios"
  }, {
    path: "/",
    component: _69ac0149,
    name: "index"
  }, {
    path: "/carteira/detalhes/:orderId",
    component: _35218cbe,
    name: "carteira-detalhes-orderId"
  }, {
    path: "/ativacao/:company?",
    component: _1fbe9674,
    name: "ativacao-company"
  }, {
    path: "/parceiros/:slug",
    component: _06a47705,
    name: "parceiros-slug"
  }, {
    path: "/parceiros/:slug/campanhas",
    component: _eef5ef3c,
    name: "parceiros-slug-campanhas"
  }, {
    path: "/parceiros/:slug/editar",
    component: _2f59bb98,
    name: "parceiros-slug-editar"
  }, {
    path: "/parceiros/:slug/campanhas/criar",
    component: _a0734aca,
    name: "parceiros-slug-campanhas-criar"
  }, {
    path: "/parceiros/:slug/campanhas/:campaign",
    component: _8f562a7c,
    name: "parceiros-slug-campanhas-campaign"
  }, {
    path: "/parceiros/:slug/campanhas/:campaign/editar",
    component: _9890810a,
    name: "parceiros-slug-campanhas-campaign-editar"
  }, {
    path: "/parceiros/:slug/campanhas/:campaign/unidades",
    component: _25813c45,
    name: "parceiros-slug-campanhas-campaign-unidades"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
