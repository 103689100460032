const toFormData = (formData) => {
  let data = new FormData()

  Object.entries(formData).forEach(function (field) {
    if (Array.isArray(field[1])) {
      if (field[0] === 'address') {
        for (let i = 0; i < field[1].length; i ++) {
          for (let prop in field[1][i]) {
            data.append(field[0] + '[' + i + ']' + '[' + prop + ']', field[1][i][prop])
          }
        }
      } else {
        field[1].map((element, key) => {
          data.append(field[0] + '[' + key + ']', element)
        })
      }

      return
    }

    if (typeof field[1] === 'object') {
      if (field[1] instanceof File) {
        data.append(field[0], field[1])
        return
      }

      for (let prop in field[1]) {
        data.append(field[0] + '[' + prop + ']', field[1][prop])
      }

      return
    }

    data.append(field[0], field[1])
  })

  return data
}

export default toFormData
