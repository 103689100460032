import { Factory } from 'miragejs'
import { faker } from '@faker-js/faker'
import { datetimeToUnixEpoch, getRandomBoolean } from '../utils/index'

const randomBoolean = getRandomBoolean()

export default {
  notification: Factory.extend({
    id () {
      return faker.string.uuid()
    },
    name () {
      return faker.company.buzzPhrase()
    },
    title () {
      return faker.company.buzzPhrase()
    },
    image () {
      return faker.image.urlLoremFlickr({ category: 'abstract', width: 423, height: 270 })
    },
    status () {
      return faker.number.int({ max: 1 })
    },
    clicks () {
      return faker.number.int({ max: 5000 })
    },
    message () {
      return faker.lorem.words(5)
    },
    updated_at () {
      return datetimeToUnixEpoch(faker.date.past())
    },
    created_at () {
      return datetimeToUnixEpoch(faker.date.past())
    },
    start_date () {
      return datetimeToUnixEpoch(faker.date.past())
    },
    end_date () {
      return datetimeToUnixEpoch(faker.date.future())
    },
    partner () {
      return randomBoolean
        ? {
          id: faker.string.uuid(),
          name: faker.company.name()
        }
        : null
    },
    campaign () {
      return randomBoolean
        ? {
          id: faker.string.uuid(),
          name: faker.company.name()
        }
        : null
    }
  })
}
