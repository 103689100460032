export default [
  {
    name: 'Esportes',
    icon: 'megaphone'
  },
  {
    name: 'Cinema',
    icon: 'camera'
  },
  {
    name: 'Beleza',
    icon: 'stars-light-sparkle-1'
  },
  {
    name: 'Produtos e Serviços',
    icon: 'bag-shopping-circle'
  },
  {
    name: 'Lazer',
    icon: 'album'
  },
  {
    name: 'Saúde',
    icon: 'heart-favorite-square-copy'
  },
  {
    name: 'Bem-estar e saúde',
    icon: 'heart-favorite-square-copy'
  },
  {
    name: 'Alimentos e Bebidas',
    icon: 'fork-spoon'
  },
  {
    name: 'Educação',
    icon: 'graduation'
  },
  {
    name: 'Casa e Decoração',
    icon: 'home'
  },
  {
    name: 'Mercados',
    icon: 'package-box-delivery-square'
  },
  {
    name: 'Games',
    icon: 'games'
  },
  {
    name: 'Moda e Vestuário',
    icon: 'shirt'
  },
  {
    name: 'Crédito e Empréstimo',
    icon: 'dollar-payments-conversion'
  },
  {
    name: 'Eletrônicos',
    icon: 'mobile'
  },
  {
    name: 'Viagens e Turismo',
    icon: 'traveling'
  },
  {
    name: 'Gift Cards',
    icon: 'traveling'
  },
  {
    name: 'Festival de Ofertas',
    icon: 'horn-trumpet'
  }
]
