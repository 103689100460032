export default {
  rows: [
    {
      id: 1,
      cpf: '06795763390',
      email: 'bianca.rocha@kaledo.com.br',
      checked: false
    },
    {
      id: 2,
      cpf: '06795763390',
      email: 'bianca.rocha@kaledo.com.br',
      checked: false
    },
    {
      id: 3,
      cpf: '06795763390',
      email: 'bianca.rocha@kaledo.com.br',
      checked: false
    },
    {
      id: 4,
      cpf: '06795763390',
      email: 'bianca.rocha@kaledo.com.br',
      checked: false
    },
    {
      id: 5,
      cpf: '06795763390',
      email: 'bianca.rocha@kaledo.com.br',
      checked: false
    },
    {
      id: 6,
      cpf: '06795763390',
      email: 'bianca.rocha@kaledo.com.br',
      checked: false
    },
    {
      id: 7,
      cpf: '06795763390',
      email: 'bianca.rocha@kaledo.com.br',
      checked: false
    },
    {
      id: 8,
      cpf: '12345678910',
      email: 'bianca.rocha@kaledo.com.br',
      checked: false
    }
  ],
  conflictRows: [
    {
      id: 1,
      cpf: '06795763390',
      email: 'bianca.rocha@kaledo.com.br',
      insertedEmail: 'bianca.rocha1711@gmail.com'
    },
    {
      id: 2,
      cpf: '06795763390',
      email: 'bianca.rocha@kaledo.com.br',
      insertedEmail: 'bianca.rocha1711@gmail.com'
    },
    {
      id: 3,
      cpf: '06795763390',
      email: 'bianca.rocha@kaledo.com.br',
      insertedEmail: 'bianca.rocha1711@gmail.com'
    }
  ]
}
