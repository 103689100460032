import PS from 'perfect-scrollbar'

export default {
  name: 'scroll',
  inserted (el, binding, vnode) {
    if (binding.value) {
      const callback = typeof binding.value === 'function'
        ? binding.value
        : binding.value.callback

      el.addEventListener('scroll', callback, { passive: true })
    }

    el.style.position = 'relative'
    PS.initialize(el)

    let ticks = 0
    const interval = setInterval(() => {
      PS.update(el)
      ticks++
      if (ticks >= 20) clearInterval(interval)
    }, 200)
  },
  unbind (el, binding) {
    if (binding.value) {
      el.removeEventListener('scroll', binding.callback, { passive: true })
    }

    el.style.position = 'static'
    PS.destroy(el)
  },
  componentUpdated (el) {
    PS.update(el)

    let ticks = 0
    const interval = setInterval(() => {
      PS.update(el)
      ticks++
      if (ticks >= 20) clearInterval(interval)
    }, 200)
  }
}
