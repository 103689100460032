import * as types from '@/store/types'

const middleware = async ({ store, redirect }) => {
  const authCheck = () => store.getters[types.AUTH_CHECK]

  const isLogged = authCheck()

  if (!isLogged) {
    const text = 'Você precisa estar logado para acessar outras paginas'

    store.dispatch(types.AUTH_LOGOUT)
    store.dispatch(types.FEEDBACK, { type: 'error', text })
    redirect({ name: 'login' })
  }
}

export default middleware
