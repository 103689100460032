import rest from '@/modules/rest'
import * as types from '@/store/types'
import moment from 'moment'

const now = moment().format('YYYY-MM-DD')
const thisMonthStart = moment().startOf('month').format('YYYY-MM-DD')
const lastMonthStart = moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD')
const lastMonthEnd = moment().startOf('month').subtract(1, 'day').format('YYYY-MM-DD')
const lastSixMonths = moment().subtract(6, 'month').format('YYYY-MM-DD')

const periods = {
  tm: {
    start: thisMonthStart,
    end: now
  },
  lm: {
    start: lastMonthStart,
    end: lastMonthEnd
  },
  lsm: {
    start: lastSixMonths,
    end: now,
  },
  sp: {},
}

const state = {
  report: []
}

const getters = {
  [types.REPORT_LIST]: ({ report }) => ({ ...report })
}

const mutations = {
  [types.REPORT_LIST]: (state, payload) => {
    state.report = payload
  }
}

const actions = {
  [types.REPORT_LIST]: async ({ commit, getters }) => {
    const company = getters[types.CONFIG_COMPANY_ID]
    const { data: { data } } = await rest().get(`/company/${company}/report/list`)

    commit(types.REPORT_LIST, data)
  },

  [types.REPORT]: async ({ commit, dispatch, getters }, report) => {
    try{
      const period = report.period === undefined
        ? ''
        : report.period.period === 'sp'
          ? report.period
            ? `?start_date=${report.period.start}&end_date=${report.period.end}`
            : ''
          : report.period
            ? `?start_date=${periods[report.period].start}&end_date=${periods[report.period].end}`
            : ''

      const company = getters[types.CONFIG_COMPANY_ID]
      const { data: { success } } =  await rest().get(`/company/${company}/report/${report.url}` + period)
      const message = {
        type: 'success',
        text: 'O relatório será enviado para seu email!'
      }
      dispatch(types.FEEDBACK, message)
    }
    catch(_){
      const message = {
        type: 'error',
        text: 'Ocorreu um problema ao solicitar o relatório'
      }
    }
  }
}

export default { state, getters, mutations, actions }
