/**
 * Get element's parents list.
 * @param {HTMLElement} element
 * @returns {HTMLElement[]}
 */
const getParents = (element) => {
  const parent = element.parentElement
  if (parent)
    return [parent, ...getParents(parent)]
  return []
}

export default getParents
