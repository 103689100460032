import merge from '~lodash.mergewith'
import { init } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F7aabec68d5b34f81ace38cdda3d040fd@o140313.ingest.sentry.io\u002F5529522",
    environment:"production",
    publishRelease:{"authToken":"8a2059bc9eae47e190d9866755d1e8e7efa0b4bf9ac347e0b935a6077defd9c0","org":"victor-gazotti","project":"gestor-production","release":"clube-gestor-front@1.7.14","include":".\u002Fdist","ignore":["node_modules"]},
    sourceMapStyle:"source-map",
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
