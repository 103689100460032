import { is } from './validators'
import moment from 'moment'

export const getDateFormat = (date) => {
  const isValid = is(date, 'String') && date.trim().length >= 10
  const format = !isValid
    ? null
    : /^\d{4}-\d{2}-\d{2}/.test(date)
      ? ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss']
      : /^\d{2}-\d{2}-\d{4}/.test(date)
        ? ['DD-MM-YYYY', 'DD-MM-YYYY HH:mm:ss']
        : /^\d{2}\/\d{2}\/\d{4}/.test(date)
          ? ['DD/MM/YYYY', 'DD/MM/YYYY HH:mm:ss']
          : null

  return format
}

export const convertDateFormat = (date, format) => {
  const parsedDate = moment(date, 'DD/MM/YYYY')

  return parsedDate.format(format)
}

export const getConstructor = (value) => {
  const string = Object.prototype.toString.call(value)
  const [, constructor] = /\[object (.*?)\]/.exec(string)
  return constructor
}

export const chain = (initial, callback, params) => {
  const value = params.reduce((value, args) => {
    return callback(value).apply(value, [...args])
  }, initial)

  return value
}

export const timestampToDate = (timestamp) => {
  return timestamp && moment.unix(timestamp, 'X').format('DD/MM/YYYY HH:mm:ss')
}

export const timestampToOnlyDate = (timestamp) => {
  return timestamp && moment.unix(timestamp, 'X').format('DD/MM/YYYY')
}

export const timestampToFormatedDate = (timestamp, format) => {
  return timestamp && moment.unix(timestamp, 'X').format(format)
}

export const replace = (text, args) => chain(text, text => text.replace, args)

export const minLengthValidationMessage = (data, length) => {
  return `O campo ${data} deve possuir no mínimo ${length} caracteres.`
}

export const maxLengthValidationMessage = (data, length) => {
  return `O campo ${data} deve possuir no máximo ${length} caracteres.`
}
