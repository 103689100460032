import * as types from '@/store/types'

export default function ({ route, from, store, redirect }) {
  const trialCheck = () => store.getters[types.COMPANY_TRIAL_CHECK]

  const isLogged = trialCheck()

  if (!isLogged) {
    const text = 'Seu período de teste expirou. Entre em contato com o time de vendas'

    store.dispatch(types.AUTH_LOGOUT)
    store.dispatch(types.FEEDBACK, { type: 'error', text })
    redirect({ name: 'login' })
  }
}
