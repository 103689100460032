import request from '@/modules/request'
import rest from '@/modules/rest'
import * as types from '@/store/types'

import GetUserImportsPagination from '@/store/user-import/GetUserImportsPagination'

const state = {
  list: [],
  pagination: {},
  processing: false
}

const getters = {
  [types.USER_IMPORT_LIST]: ({ list }) => list,
  [types.USER_IMPORT_PAGINATION]: ({ pagination }) => pagination,
  [types.USER_IMPORT_PROCESSING]: state => state.processing,
}

const mutations = {
  [types.USER_IMPORT_LIST]: (state, payload) => {
    state.list = [ ...payload ]
  },
  [types.USER_IMPORT_PAGINATION]: (state, payload) => {
    state.pagination = { ...payload }
  },
  [types.USER_IMPORT_PROCESSING]: (state, payload) => {
    state.processing = payload
  }
}

const actions = {
  [types.USER_IMPORT_LIST]: async ({ commit }, params = {}) => {
    const { imports: { imports, ...pagination } } = await request(GetUserImportsPagination, {
      page: params.page,
      limit: params.limit
    })

    commit(types.USER_IMPORT_LIST, imports)
    commit(types.USER_IMPORT_PAGINATION, pagination)
  },
  [types.USER_IMPORT_PAGINATION]: async ({ dispatch }) => {
    const { page, limit } = getters(types.USER_IMPORT_PAGINATION)

    await dispatch(types.USER_IMPORT_LIST, { page: page + 1 || 1, limit: limit || 10 })
  },
  [types.USER_IMPORT_FILE]: async ({ getters, dispatch }, { file, type }) => {
    try {
      const company = getters[types.CONFIG_COMPANY_ID]

      const data = new FormData()
      data.append('file', file)
      data.append('type', type)

      const { data: {data: success } } = await rest().post(`/company/${company}/import-preview`, data)

      const text = success
        ? 'O arquivo foi importado'
        : 'Não foi possível importar o arquivo'

      const message = {
        type: success ? 'success' : 'error',
        text
      }
      dispatch(types.FEEDBACK, message)

      return success
    } catch (_) {
      const text = 'Não foi possível importar o arquivo'

      const message = {
        type: 'error',
        text
      }
      dispatch(types.FEEDBACK, message)

      return false
    }
  },
  [types.USER_IMPORT_CONFIRMATION]: async ({ getters, dispatch }, id) => {
    try {
      const company = getters[types.CONFIG_COMPANY_ID]

      const { data: { success } } = await rest().post(`company/${company}/import/${id}/`)

      const text = success
        ? 'A importação foi confirmada'
        : 'Não foi possível confirmar a importação'

      const message = {
        type: success ? 'success' : 'error',
        text
      }
      dispatch(types.FEEDBACK, message)

      return success
    } catch (_) {
      const text = 'Não foi possível confirmar a importação'

      const message = {
        type: 'error',
        text
      }
      dispatch(types.FEEDBACK, message)

      return false
    }
  },
  [types.USER_IMPORT_MANUAL]: async ({ getters, dispatch }, documents) => {
    try {
      const company = getters[types.CONFIG_COMPANY_ID]

      const { data: { data, success } } = await rest().post(`/company/${company}/import-manual`, documents)

      const text = success
        ? 'A importação foi efetuada'
        : 'Não foi possível efetuar a importação'

      const message = {
        type: success ? 'success' : 'error',
        text
      }
      dispatch(types.FEEDBACK, message)

      return data
    } catch (_) {
      const text = 'Não foi possível efetuar a importação'

      const message = {
        type: 'error',
        text
      }
      dispatch(types.FEEDBACK, message)

      return false
    }
  },
  [types.USER_IMPORT_CANCEL]: async ({ getters, dispatch }, id) => {
    try {
      const company = getters[types.CONFIG_COMPANY_ID]

      const { data: { data: success } } = await rest().post(`/company/${company}/import/${id}/cancel`)

      const text = success
        ? 'A importação foi cancelada'
        : 'Não foi possível cancelar a importação'

      const message = {
        type: success ? 'success' : 'error',
        text
      }
      dispatch(types.FEEDBACK, message)

      return success
    } catch (_) {
      const text = 'Não foi possível cancelar a importação'

      const message = {
        type: 'error',
        text
      }
      dispatch(types.FEEDBACK, message)

      return false
    }
  },
  [types.USER_IMPORT_PROCESSING]: async ({ getters, commit }) => {
    try {
      const company = getters[types.CONFIG_COMPANY_ID]

      const { data: { data: processing } } = await rest().get(`/company/${company}/import/progress`)

      commit(types.USER_IMPORT_PROCESSING, processing)

      return processing
    } catch (e) {
      return false
    }
  },
  [types.USER_IMPORT_DOWNLOAD_CPFS_ENABLED]: async ({ getters, dispatch }) => {

    try {
      const company = getters[types.CONFIG_COMPANY_ID]
      const {
        data: {
          data: {
            processing: success
          } = {}
        } = {}
      } = {} = await rest().get(`company/${company}/report/documents`)

      const text = success
        ? 'Download em processo. Relatório será enviado por e-mail.'
        : 'Não foi possível baixar os cfps habilitados.'

      const message = { type: success ? 'success' : 'error', text }
      dispatch(types.FEEDBACK, message)

    } catch(error) {
      return error
    }
  }
}

export default { state, getters, mutations, actions }
