import Vue from 'vue'

Vue.mixin({
  data () {
    return {
      matchMedia: window.matchMedia('(min-width: 769px)'),
      lgScreenMatch: window.matchMedia('(min-width: 769px) and (max-width: 1500px'),
      isLgScreen: false,
      isDesktop: false
    }
  },
  methods: {
    setBreakpoint () {
      this.isDesktop = this.matchMedia && this.matchMedia.matches
    }
  },
  beforeDestroy () {
    this.matchMedia.removeListener(this.setBreakpoint)
    this.lgScreenMatch.removeListener(this.setBreakpointLgScreen)
  },
  mounted () {
    this.matchMedia.addListener(this.setBreakpoint)
    this.lgScreenMatch.addListener(this.setBreakpointLgScreen)
    this.setBreakpoint()
    this.setBreakpointLgScreen()
  },
  methods: {
    setBreakpoint () {
      this.isDesktop = this.matchMedia && this.matchMedia.matches
    },
    setBreakpointLgScreen() {
      this.isLgScreen = this.lgScreenMatch && this.lgScreenMatch.matches
    }
  }
})
