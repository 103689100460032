import * as types from '@/store/types'
import rest from '@/modules/rest'
import { FEEDBACK } from '@/store/types'

const state = {
  award_step: 1,
  award_order: {},
  orders_list: [],
  awards_campaigns: []
}

const getters = {
  [types.AWARD_STEP]: ({ award_step }) => award_step,
  [types.AWARD_ORDER]: ({ award_order }) => award_order,
  [types.ORDERS_LIST]: ({ orders_list }) => orders_list,
  [types.AWARDS_CAMPAIGNS]: ({ awards_campaigns }) => awards_campaigns
}

const mutations = {
  [types.AWARD_ORDER]: (state, payload) => {
    state.award_order = payload
  },
  [types.AWARD_STEP]: (state, payload) => {
    state.award_step = payload
  },
  [types.ORDERS_LIST]: (state, payload) => {
    state.orders_list = payload.data
  },
  [types.AWARDS_CAMPAIGNS]: (state, payload) => {
    state.awards_campaigns = payload.data
  }
}

const actions = {
  [types.AWARDS_CREATE_ORDER]: async ({ dispatch }, payload) => {
    try {
      const res = await rest().post('/awards', payload)
      if (res && res.data && res.data.success) {
        const feedback = {
          type: 'success',
          title: 'Pedido criado com sucesso',
          text: ''
        }
        dispatch(types.FEEDBACK, feedback)
      } else {
        const feedback = {
          type: 'error',
          title: 'Houve um erro ao criar o seu pedido',
          text: 'Tente novamente mais tarde'
        }
        dispatch(types.FEEDBACK, feedback)
      }
      return res.data.success
    } catch (e) {
      const feedback = {
        type: 'error',
        title: 'Houve um erro ao criar o seu pedido',
        text: 'Tente novamente mais tarde'
      }
      dispatch(types.FEEDBACK, feedback)
      return false
    }
  },
  [types.GET_AWARDS_DETAILS]: async ({ dispatch, commit }, payload) => {
    const id = payload

    try {
      const awardDetails = await rest().get(`/awards/${id}`)
      commit(types.AWARD_ORDER, awardDetails.data.data)
      return true
    } catch (e) {
      const feedback = {
        type: 'error',
        title: 'Houve um erro ao carregar informações da premiação',
        text: 'Tente novamente mais tarde'
      }
      dispatch(types.FEEDBACK, feedback)
      return false
    }
  },
  [types.GET_AWARDS_CAMPAIGNS]: async ({ commit }) => {
    const awardsCampaigns = await rest().get('/awards/campaigns')
    commit(types.AWARDS_CAMPAIGNS, awardsCampaigns.data)
  },
  [types.AWARDS_GET_ORDERS_LIST]: async ({ commit }, variables) => {
    const orders_list = await rest().get('/awards?', {
      params: {
        ...variables
      }
    })
    commit(types.ORDERS_LIST, orders_list)
  },
  [types.CANCEL_ORDER]: async ({ dispatch }, payload) => {
    const { id } = payload
    const res = await rest().put(`/awards/${id}`)

    if (res && res.data && res.data.success) {
      dispatch(FEEDBACK, {
        type: 'success',
        title: 'Pedido cancelado com sucesso!',
        text: ''
      })
    } else {
      dispatch(FEEDBACK, {
        type: 'error',
        title: 'Ocorreu um problema ao cancelar o pedido',
        text: ''
      })
    }
    return res
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
