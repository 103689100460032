import * as types from '@/store/types'

const middleware = async ({ store, route, redirect }) => {
  const token = route.query.token || store.getters[types.AUTH].token

  if (token) {
    try {
      await store.dispatch(types.AUTH_DECODE, { token })
      redirect({ name: 'index' })
    } catch (error) {
      const text = 'Token inválido'
      store.dispatch(types.FEEDBACK, { type: 'error', text })
      redirect({ name: 'login' })
    }
  }
}

export default middleware
