import * as types from '@/store/types'
import request from '@/modules/request'

import GetCategoriesList from '@/store/category/GetCategoriesList'

const state = {
  categories: []
}

const getters = {
  [types.CATEGORIES]: ({ categories }) => [ ...categories ]
}

const mutations = {
  [types.CATEGORIES]: (state, payload) => {
    state.categories = payload
  }
}

const actions = {
  [types.CATEGORIES]: async ({ commit, getters }) => {
    const { categories } = await request(GetCategoriesList)
    commit(types.CATEGORIES, categories)
  }
}

export default { state, getters, mutations, actions }
