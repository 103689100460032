import axios from 'axios'
import store from '@/store'
import * as types from '@/store/types'

const baseURL = process.env.restUrl

const rest = () => {
  const { token } = store().getters[types.AUTH]

  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      ...token && { 'Authorization': `Bearer ${token}` }
    }
  })
}

export default rest
