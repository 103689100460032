const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['breadcrumb'] = require('../middleware/breadcrumb.js')
middleware['breadcrumb'] = middleware['breadcrumb'].default || middleware['breadcrumb']

middleware['checkauth'] = require('../middleware/checkauth.js')
middleware['checkauth'] = middleware['checkauth'].default || middleware['checkauth']

middleware['checkToken'] = require('../middleware/checkToken.js')
middleware['checkToken'] = middleware['checkToken'].default || middleware['checkToken']

middleware['trial'] = require('../middleware/trial.js')
middleware['trial'] = middleware['trial'].default || middleware['trial']

export default middleware
