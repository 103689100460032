import * as types from '@/store/types'
import request from '@/modules/request'
import lightness from 'lightness'

import GetConfigs from '@/store/config/GetConfig'

const state = {
  user: {},
  companies: [],
  company_id: '',
  personalization: {},
  companiesActive: [],
  companiesEnabled: [],
  logo: '',
}

const getters = {
  [types.CONFIG_COMPANY_ID]: ({ company_id }) => company_id,
  [types.CONFIG_COMPANY_LIST]: ({ companies }) => [ ...companies ],
  [types.CONFIG_PERSONALIZATION]: ({ personalization }) => ({ ...personalization }),
  [types.CONFIG_COMPANIES_ACTIVE]: ({ companiesActive }) => [ ...companiesActive ],
  [types.CONFIG_COMPANIES_ENABLED]: ({ companiesEnabled }) => [ ...companiesEnabled ],
  [types.CONFIG_COMPANY_LOGO]: ({ logo }) => logo,
  [types.CONFIG_USER]: (state) => {
    const user = JSON.parse(localStorage.getItem('user'))
    return { ...user, ...state.user }
  },
  [types.CONFIG_UNIFIED_REGISTRATION]: ({ personalization }) => personalization.config.unified_registration,
}

const mutations = {
  [types.CONFIG_USER]: (state, payload) => {
    const birthday = payload.birthday
      ? new Date(payload.birthday).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' })
      : ''
    const user = { ...payload, birthday}

    localStorage.setItem('user', JSON.stringify({ ...user }))
    state.user = user
  },

  [types.CONFIG_COMPANY_ID]: (state, payload) => {
    localStorage.setItem('company_id', payload.company_id)
    state.company_id = payload.company_id
    state.logo = payload.company_logo
  },

  [types.CONFIG_RESET]: (state) => {
    localStorage.removeItem('company_id')
    localStorage.removeItem('permission')
  },

  [types.CONFIG_COMPANY_LIST]: (state, payload) => {
    state.companies = [ ...payload ]
  },

  [types.CONFIG_COMPANIES_ACTIVE]: (state, payload) => {
    state.companiesActive = [ ...payload ]
  },

  [types.CONFIG_COMPANIES_ENABLED]: (state, payload) => {
    state.companiesEnabled = [ ...payload ]
  },

  [types.CONFIG_RESET_PERSONALIZATION]: (state) => {
    const defaultColors = { primary: '#121E48', secondary: '#B7BBC8', tertiary: '#F5A623' }

    Object.keys(defaultColors).forEach((colorName) => {
      const color = defaultColors[colorName]

      document.body.style.setProperty(`--color-${colorName}`, color)
      document.body.style.setProperty(`--color-${colorName}-9`, lightness(color, +10))
      document.body.style.setProperty(`--color-${colorName}-8`, lightness(color, +20))
      document.body.style.setProperty(`--color-${colorName}-5`, lightness(color, +50))
    })
  },

  [types.CONFIG_PERSONALIZATION]: (state, payload) => {
    const { config, name, url, logo } = payload || {}
    state.personalization = { config, name, url, logo }

    if (config && config.colors) {
      const defaultColors = { primary: '#121E48', secondary: '#B7BBC8', tertiary: '#F5A623' }

      Object.keys(defaultColors).forEach((colorName) => {
        const color = config.colors[colorName] || defaultColors[colorName]

        document.body.style.setProperty(`--color-${colorName}`, color)
        document.body.style.setProperty(`--color-${colorName}-9`, lightness(color, +10))
        document.body.style.setProperty(`--color-${colorName}-8`, lightness(color, +20))
        document.body.style.setProperty(`--color-${colorName}-5`, lightness(color, +50))
      })
    }
  }
}

const actions = {
  [types.CONFIG_PERSONALIZATION]: async ({ getters, commit }, companyId) => {
    const config = getters[types.COMPANY]
    commit(types.CONFIG_PERSONALIZATION, config)
  }
}

export default { state, getters, mutations, actions }
