import * as types from '@/store/types'
import request from '@/modules/request'
import rest from '@/modules/rest'
import toFormData from '@/modules/convertFormType'

import GetPartnersPagination from '@/store/partner/GetPartnersPagination'
import TogglePartnerStatus from '@/store/partner/TogglePartnerStatus'
import GetPartnerCount from '@/store/partner/GetPartnerCount'
import DeletePartner from '@/store/partner/DeletePartner'
import GetPartner from '@/store/partner/GetPartner'
import GetPartners from '@/store/partner/GetPartners'

import UpdatePartner from '@/store/partner/UpdatePartner'

const state = {
  list: [],
  partner: {},
  partnerCount: {},
  pagination: { list: [], address: [] },
  partners: []
}

const getters = {
  [types.PARTNER_LIST]: ({ list }) => [...list],
  [types.PARTNER]: ({ partner }) => ({ ...partner }),
  [types.PARTNERS]: ({ partners }) => ({ ...partners }),
  [types.PARTNER_COUNT]: ({ partnerCount }) => ({ ...partnerCount }),
  [types.PARTNER_LIST_PAGINATION]: ({ pagination }) => ({ ...pagination })
}

const mutations = {
  [types.PARTNER]: (state, payload) => {
    state.partner = payload
  },
  [types.PARTNERS]: (state, payload) => {
    state.partners = payload
  },
  [types.PARTNER_LIST]: (state, payload) => {
    state.list = payload
  },

  [types.PARTNER_CLEAR]: (state) => {
    state.pagination = { list: [], address: [] }
    state.partner = {}
    state.list = []
  },

  [types.PARTNER_LIST_PAGINATION]: (state, payload) => {
    const list = [...state.pagination.list, ...payload.list]
    state.pagination = { ...payload, list }
  },

  [types.PARTNER_LIST_FILTER]: (state, payload) => {
    state.list = payload.list
    state.pagination = payload
  },

  [types.PARTNER_TOGGLE_STATUS]: (state, { slug, isExclusive, partner }) => {
    const partnerType = isExclusive ? 'exclusive_enabled' : 'public_available'
    const statusProp = isExclusive ? 'status' : 'is_blocked'

    if (state.partner.slug === slug) { state.partner[statusProp] = partner[statusProp] }

    state.partnerCount[partnerType] = partner[statusProp]
      ? ++state.partnerCount[partnerType]
      : --state.partnerCount[partnerType]

    state.list.concat(state.pagination.list)
      .forEach(p => { if (p.slug === slug) p[statusProp] = partner[statusProp] })
  },

  [types.PARTNER_COUNT]: (state, payload) => {
    state.partnerCount = payload
  }
}

const actions = {
  [types.PARTNER]: async ({ commit }, variables) => {
    const { partner } = await request(GetPartner, variables)
    commit(types.PARTNER, partner)
    return partner
  },

  [types.PARTNER_CLEAR]: async ({ commit }) => {
    commit(types.PARTNER_CLEAR)
    commit(types.ADDRESS_CLEAR)
  },

  [types.PARTNER_LOGO_UPLOAD]: async ({ getters }, { partner, file }) => {
    const company = getters[types.CONFIG_COMPANY_ID]
    const data = new FormData()
    data.append('logo', file)
    data.append('_method', 'PATCH')

    await rest().post(`/company/${company}/perk/${partner}`, data)
  },

  [types.PARTNER_ADDRESS_UPLOAD]: async ({ getters }, { partner, file }) => {
    const company = getters[types.CONFIG_COMPANY_ID]
    const data = new FormData()
    data.append('file', file)

    const response = rest().post(`company/${company}/perk/${partner}/import`, data)
  },

  [types.PARTNER_CREATE]: async ({ dispatch }, variables) => {
    try {
      const data = toFormData(variables.partner)

      const response = await rest().post('perk', data)
      const message = { type: 'success', text: 'Parceiro criado com sucesso' }

      dispatch(types.PARTNER_LIST_FILTER, { exclusives: true })
      dispatch(types.FEEDBACK, message)
    } catch (_) {
      const message = { type: 'error', text: 'Ocorreu um problema ao criar o parceiro' }
      dispatch(types.FEEDBACK, message)
    }
  },

  [types.PARTNER_UPDATE]: async ({ commit, dispatch }, variables) => {
    try {
      if (variables.partner.logo instanceof File) {
        dispatch(types.PARTNER_LOGO_UPLOAD, {
          partner: variables.id,
          file: variables.partner.logo
        })
      }

      delete variables.partner.logo

      const { partner } = await request(UpdatePartner, variables)

      const message = {
        type: 'success',
        text: `Parceiro ${variables.partner.name} editado com sucesso`
      }

      commit(types.PARTNER, partner)
      dispatch(types.FEEDBACK, message)
    } catch (_) {
      const message = {
        type: 'error',
        text: `Ocorreu um problema ao editaro parceiro ${variables.partner.name}`
      }

      dispatch(types.FEEDBACK, message)
    }
  },

  [types.PARTNER_DELETE]: async ({ dispatch }, variables) => {
    const { status } = await request(DeletePartner, variables)

    if (status) {
      const feedback = { type: 'success', text: 'Parceiro deletado com sucesso!' }

      dispatch(types.FEEDBACK, feedback)
    } else {
      const feedback = { type: 'success', text: 'Houve um erro ao deletar o parceiro!' }
      dispatch(types.FEEDBACK, feedback)
    }
  },
  [types.GET_PARTNER_CAMPAIGNS]: async ({ commit }, payload) => {
    const { pagination } = await request(GetPartners, payload)
    commit(types.PARTNERS, pagination)
  },
  [types.PARTNER_LIST_PAGINATION]: async ({ commit, getters }, filters) => {
    const company = getters[types.CONFIG_COMPANY_ID]
    // since the "status" filter does't work for the partners list, we have to
    // replace it for a working status "blocked/unblocked"
    if (!filters.exclusives && filters.status === '0') {
      const { status, ...newFilters } = filters
      filters = { ...newFilters, blocked: company }
    } else if (!filters.exclusives && filters.status === '1') {
      const { status, ...newFilters } = filters
      filters = { ...newFilters, unblocked: company }
    }
    const { pagination } = await request(GetPartnersPagination, filters)

    commit(types.PARTNER_LIST_PAGINATION, pagination)
  },

  [types.PARTNER_LIST_FILTER]: async ({ commit, getters }, filters, id) => {
    const company = getters[types.CONFIG_COMPANY_ID]
    if (!filters.exclusives && filters.status === '0') {
      const { status, ...newFilters } = filters
      filters = { ...newFilters, blocked: company }
    } else if (!filters.exclusives && filters.status === '1') {
      const { status, ...newFilters } = filters
      filters = { ...newFilters, unblocked: company }
    }
    const { pagination } = await request(GetPartnersPagination, filters)
    commit(types.PARTNER_LIST_FILTER, pagination)
  },

  [types.PARTNER_TOGGLE_STATUS]: async ({ commit, dispatch }, { ...variables }) => {
    try {
      const response = await request(TogglePartnerStatus, variables)
      const partner = response[Object.keys(response)[0]]

      const status = partner.is_exclusive ? partner.status : partner.is_blocked
      const text = 'Status alterado para ' + (status ? 'ativo' : 'inativo')

      dispatch(types.FEEDBACK, { type: 'success', text })
      commit(types.PARTNER_TOGGLE_STATUS, { ...variables, partner })
    } catch (_) {
      const text = 'Ocorreu um problema ao alterar o status'
      dispatch(types.FEEDBACK, { type: 'error', text })
    }
  },

  [types.PARTNER_COUNT]: async ({ commit }) => {
    const { partnerCount } = await request(GetPartnerCount)
    commit(types.PARTNER_COUNT, partnerCount)
  }
}

export default { state, getters, mutations, actions }
